import React, { Suspense } from 'react';
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import store from '../store';
import Routes from './routes';
import ScrollToTop from '../components/common/scroll';
import Loader from '../components/common/loader';
import { axiosInterceptor } from '../intercepter';
// import { authenticate } from 'store/login/loginActions';
// import { AUTHENTICATE_FAILED } from 'store/login/loginTypes';
// import STORAGE_KEYS from 'keys';
const { PUBLIC_URL } = process.env;

axiosInterceptor();

// const token = JSON.parse(localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN));
// const loggedInUser = JSON.parse(localStorage.getItem("user"));

// if (token && loggedInUser) {
//   store.dispatch(authenticate(loggedInUser));
// } else {
//   store.dispatch({ type: AUTHENTICATE_FAILED });
// }

const AppContainer = () => (
  <Provider store={store}>
    <Suspense fallback={<Loader isSuspense />}>
      <Loader>
        <BrowserRouter basename={PUBLIC_URL}>
          <ScrollToTop>
            <Routes />
          </ScrollToTop>
        </BrowserRouter>
      </Loader>
    </Suspense>
  </Provider>
)

export default AppContainer;