import { Button, Col, Form, message, Row, Spin, Table, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { uploadFile } from "../../../Redux/uploadSlice";
import { useDispatch } from "react-redux";
import {
  fillClientForm,
  getClientDetail,
} from "./../../../Redux/clientFormSlice";

import axios from "axios";
import { API_BASE } from "../../../constants/api";
import AddNote from "../../../components/common/AddNote";
import { FilePdfOutlined } from "@ant-design/icons";
// import PDFViewer from "pdf-viewer-reactjs";

function Ndnc(prop) {
  const baseURL = "https://clienton-boarding.s3.amazonaws.com/"
  const [fileList, setFileList] = useState([]);
  const [fileList1, setFileList1] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [links, setLinks] = useState([]);

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [notes, setNotes] = useState();
  const [totalNotes, setTotalNotes] = useState();
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [pageName, setPageName] = useState();
  const [NdncData, setNdncData] = useState();
  const [excelInfoVisible, setexcelInfoVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    if(pageName){
    const data = {
      limit: 10000,
      skip,
      clientID: clientid,
      notesType: pageName === "NDNC" ? 1 : 2,
    };

    //setIsLoading(true);
    axios
      .post(`${API_BASE}v1/clientForm/getClientNotesList`, data)
      .then((res) => {
        //setIsLoading(false);
        if (res.data.statusCode === 200) {
          setNotes(
            res.data.data.list.map((data) => {
              return { ...data, key: data._id };
            })
          );
          setTotalNotes(res.data.data.list.length);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        ////setIsLoading(false);
        console.log(err);
      });
    }
  }, [skip, excelInfoVisible]);

  useEffect(() => {
    setPageName(prop.pageName);
  }, [prop]);

  useEffect(() => {
    if(pageName){
    let userID = localStorage.getItem("userID");
    let client = localStorage.getItem("clientID");
    let url, data;
    if(pageName === "NDNC"){
      url ="user/generateNDNCPdf"
      data = { userId: userID }
    } else {
      url = "clientForm/generateSLA_3_Pdf"
      data = { client }
    }
    
    setIsLoading(true)
    axios
      .post(`${API_BASE}v1/${url}`, data)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {    
          //console.log(res.data.data.generatedNDNCPdf)  
          const file = pageName === "NDNC" ? res?.data?.data?.generatedNDNCPdf : res?.data?.data?.generatedSLAPdf 
          let fileName = file[file.length-1]
          let tarray = [
            {
              uid: -1,
              name: 'Generated Doc',
              status: "done",
              url: res.data.data.url + fileName 
            },
          ];
          setFileList1(tarray);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
    }
  }, [pageName]);

  //getting Client Step 4 data
  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    if (pageName) {
      
      dispatch(getClientDetail(clientid))
        .then((res) => {         
          if (res.payload.statusCode === 200) {            
            setNdncData(res.payload.data);
            let tarray = [];
            if (pageName === "NDNC") {
              if (res.payload.data?.NDNCDoc?.length) {
                res.payload.data.NDNCDoc.map((data,index) => {
                  tarray.push({
                    uid: index,
                    name: "Uploaded " + pageName + " File",
                    status: "done",
                    url: baseURL + "ndnc-pdf/"+  data,                    
                  },)
                })
                setFileList(tarray);
              }
            } else {
              if (pageName === "SLA") {
                if (res.payload.data.SLADoc.length) {
                  res.payload.data.SLADoc.map((data,index) => {
                    tarray.push({
                      uid: index,
                      name: "Uploaded " + pageName + " File",
                      status: "done",
                      url: baseURL + "sla-pdf/" +  data,                    
                    },)
                  })
                  setFileList(tarray);
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [pageName]);

  useEffect(() => {
    setFileList2([...fileList, ...fileList1])
  }, [fileList, fileList1])

  //getting client Notes
  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    const data = {
      limit: 10000,
      skip,
      clientID: clientid,
      notesType: pageName === "NDNC" ? 1 : 2,
    };

    if (pageName) {
      //setIsLoading(true);
      axios
        .post(`${API_BASE}v1/clientForm/getClientNotesList`, data)
        .then((res) => {
          //setIsLoading(false);
          if (res.data.statusCode === 200) {
            setNotes(
              res.data.data.list.map((data) => {
                return { ...data, key: data._id };
              })
            );
            setTotalNotes(res.data.data.list.length);
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          //setIsLoading(false);
          console.log(err);
        });
    }
  }, [skip, pageName]);

  const onFinish = (values) => {
    prop.setCurrent((prev) => prev + 1);
    
    // let links1;
    // if (fileList?.[0]?.url) {
    //   let abc = ["/" + fileList?.[0]?.url?.split?.("/").slice(3).join("/")];
    //   setLinks(abc);
    // } 
    // else {
    //   const allPromises = fileList.map((file) => {
    //     let formData = new FormData();
    //     formData.append("file", file.originFileObj);
    //     formData.append("moduleName", "ndncPdf");
    //     return dispatch(uploadFile(formData));
    //   });

    //   Promise.all(allPromises).then((res) => {
    //     links1 = res.map((data1) => data1.payload.data.name);
    //     setLinks(links1);
    //   });
    // }
  };

  useEffect(() => {
    // let data;
    // let userID = localStorage.getItem("userID");
    // if (links.length) {
    //   data = {
    //     userID,
    //     [pageName + "Doc"]: links?.[0],
    //     mainStepNo: pageName === "NDNC" ? 4 : 7,
    //     subStepNo: 0,
    //     status: pageName === "NDNC" ? 2 : 7,
    //   };

    //   dispatch(fillClientForm(data))
    //     .then((res) => {
    //       if (res.payload.statusCode === 200) {
    //         message.success(res.payload.message);
    //         prop.setCurrent((prev) => prev + 1);
    //       } else {
    //         message.error(res.payload.message);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
  }, [links]);

  //console.log(fileList2)
  const props = {
    fileList :fileList2,    
    accept: ".pdf",
    beforeUpload: () => {
      return false;
    },
    showUploadList: {
      showRemoveIcon: false,
    },
  };

  const columns = [
    {
      title: "Change Request Notes",
      dataIndex: "notes",
      key: "Notes",
    },
  ];

  const note = () => {
    setexcelInfoVisible(true);
  };

  return (
    <Form
      ref={prop.refs}
      form={form}
      name="basic12"
      onFinish={onFinish}
      autoComplete="off"
    >
      <>
        <Row>
        <Col span={4}>
          <h2>{pageName}</h2>
        </Col>
          <Col offset={16} span={4}>
            <Button className="btn-primary" type="primary" onClick={note}>
              Add Note
            </Button>
          </Col>
        </Row>

      {isLoading ? 
                  <Spin /> : <>
        <div className="event-wrap">
          <Row>
            {/* <div className="col-lg-6">
              <Form.Item name="Ndnc" label={pageName + " Doc"} className="upload-pdf">
                {isLoading ? (
                  <Spin />
                ) : (
                  <Upload {...props}>
                    
                    <FilePdfOutlined />
                  </Upload>
                )}
              </Form.Item>
            </div> */}
            <Upload {...props}>
              {/* <Button icon={<UploadOutlined />}>Upload</Button> */}
            </Upload>
          </Row>

          <Row>
            <div className="col-lg-12">
              <div className="common-table">
                <Table
                  bordered
                  dataSource={notes}
                  columns={columns}
                  scroll={{ x: 800 }}
                  loading={isLoading}
                  pagination={{
                    current: page,
                    defaultPageSize: limit,
                    total: totalNotes,
                    showTotal: (total) => `Total ${total} records`,
                    onChange: (page, pagesize) => {
                      setPage(page);
                      setSkip((page - 1) * pagesize);
                    },
                  }}
                />
              </div>
            </div>
          </Row>

          <AddNote
            notesType={pageName === "NDNC" ? 1 : 2}
            setexcelInfoVisible={setexcelInfoVisible}
            excelInfoVisible={excelInfoVisible}
          />
        </div>
        </>}
      </>
    </Form>
  );
}

export default Ndnc;
