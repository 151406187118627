import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Timeline,
  TimePicker,
} from "antd";
import { Menu, Space } from "antd";
// import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useRef, useState } from "react";
// import { Link } from "react-router-dom";
import "../../../pages/BD User/Meeting/meeting.scss";
import moment, { utc } from "moment";
import "moment-timezone";
import Down from "../../../SVG/Down";
import Calender from "../Calender";
import {
  scheduleMeeting,
  getListOfUpcommingMeeting,
  getMonthlyListOfUpcommingMeeting,
  updateMeeting,
  stopRecurringMeeting,
} from "../../../Redux/scheduleMeeting";
import { useDispatch } from "react-redux";
import { ContainerOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Content } from "antd/lib/layout/layout";
import Breadcrumbs from "../Breadcrumb";
import AddNote from "../AddNote";
import axios from "axios";
import { API_BASE } from "../../../constants/api";
//moment.tz.setDefault("America/New_York");

const { Option } = Select;
const format = "HH:mm";
const { TabPane } = Tabs;
const color = [
  {
    label: "1",
    key: "#0F6943",
  },
  {
    label: "2",
    key: "#462D91",
  },
  {
    label: "3",
    key: "#5D5A09",
  },
  {
    label: "4",
    key: "#91512D",
  },
  {
    label: "5",
    key: "#2D7391",
  },
  {
    label: "6",
    key: "#5D093E",
  },
  {
    label: "7",
    key: "rgb(111, 66, 66)",
  },
  {
    label: "8",
    key: "rgb(195, 0, 0)",
  },
  {
    label: "9",
    key: "rgb(220, 111, 111)",
  },
  {
    label: "10",
    key: "rgb(248, 117, 49)",
  },
  {
    label: "11",
    key: "rgb(69, 118, 163)",
  },
  {
    label: "12",
    key: "rgb(31, 189, 102)",
  },
  {
    label: "13",
    key: "rgb(88, 60, 60)",
  },
  {
    label: "14",
    key: "rgb(247, 176, 67)",
  },
];

const weekly = [
  {
    label: "Monday",
    key: "1",
  },
  {
    label: "Tuesday",
    key: "2",
  },
  {
    label: "Wednesday",
    key: "3",
  },
  {
    label: "Thursday",
    key: "4",
  },
  {
    label: "Friday",
    key: "5",
  },
  {
    label: "Saturday",
    key: "6",
  },
  {
    label: "Sunday",
    key: "7",
  },
];

const weekly1 = [
  {
    label: "20 Minutes",
    key: "20",
  },
  {
    label: "30 Minutes",
    key: "30",
  },
  {
    label: "45 Minutes",
    key: "45",
  },
  {
    label: "60 Minutes",
    key: "60",
  },
];

const monthly = [
  {
    label: "1st Day",
    key: "1",
  },
  {
    label: "2nd Day",
    key: "2",
  },
  {
    label: "3rd Day",
    key: "3",
  },
  {
    label: "4th Day",
    key: "4",
  },
  {
    label: "5th Day",
    key: "5",
  },
  {
    label: "6th Day",
    key: "6",
  },
  {
    label: "7th Day",
    key: "7",
  },
  {
    label: "8th Day",
    key: "8",
  },
  {
    label: "9th Day",
    key: "9",
  },
  {
    label: "10th Day",
    key: "10",
  },
  {
    label: "11th Day",
    key: "11",
  },
  {
    label: "12th Day",
    key: "12",
  },
  {
    label: "13th Day",
    key: "13",
  },
  {
    label: "14th Day",
    key: "14",
  },
  {
    label: "15th Day",
    key: "15",
  },
  {
    label: "16th Day",
    key: "16",
  },
  {
    label: "17th Day",
    key: "17",
  },
  {
    label: "18th Day",
    key: "18",
  },
  {
    label: "19th Day",
    key: "19",
  },
  {
    label: "20th Day",
    key: "20",
  },
  {
    label: "21th Day",
    key: "21",
  },
  {
    label: "22th Day",
    key: "22",
  },
  {
    label: "23th Day",
    key: "23",
  },
  {
    label: "24th Day",
    key: "24",
  },
  {
    label: "25th Day",
    key: "25",
  },
  {
    label: "26th Day",
    key: "26",
  },
  {
    label: "27th Day",
    key: "27",
  },
  {
    label: "28th Day",
    key: "28",
  },
  {
    label: "29th Day",
    key: "29",
  },
  {
    label: "30th Day",
    key: "30",
  },
  {
    label: "31th Day",
    key: "31",
  },
];

const monthly1 = [
  {
    label: "January",
    key: "0",
  },
  {
    label: "February",
    key: "1",
  },
  {
    label: "March",
    key: "2",
  },
  {
    label: "April",
    key: "3",
  },
  {
    label: "May",
    key: "4",
  },
  {
    label: "June",
    key: "5",
  },
  {
    label: "July",
    key: "6",
  },
  {
    label: "August",
    key: "7",
  },
  {
    label: "September",
    key: "8",
  },
  {
    label: "October",
    key: "9",
  },
  {
    label: "November",
    key: "10",
  },
  {
    label: "December",
    key: "11",
  },
];

const CommonMeeting = ({
  clientList,
  meetingId,
  type,
  meetingData,
  setApiToggle,
  apiToggle,
  umdata,
  setexcelInfoVisible,
  excelInfoVisible
}) => {
  const navigate = useNavigate();
  const [upcomingMeeting, setUpcomingMeeting] = useState();
  const onChange1 = (key, date, dateString) => {
    //console.log(key, date, dateString);
    setKey(key);
  };

  

  function renderContent(tab) {
    switch (tab) {
      case 1:
        return recurring();
      case 2:
        return <Calender />;
    }
  }
  useEffect(() => {
    if (type === "add" && umdata) {
      setUpcomingMeeting(umdata);
    }
  }, [umdata, type]);
  
  const recurring = () => {
    return (
      <>
        {/* <Layout className="site-layout-background">
          <Content className="admin-webpanel-content">
            <Breadcrumbs content="Edit profile" />
            <div className="without-layout">
              <div className="right-panel"> */}
                <div className="row">
                  <div className="col-lg-12">
                    <Tabs
                      defaultActiveKey="1"
                      onChange={onChange1}
                      activeKey={key}
                    >
                      <TabPane className="event-wrap" tab="Weekly" key="1">
                        {key == 1 && (
                          <Row className="pt-15 align-item-center">
                            <div className="col-lg-2 time line-col">
                              On Every
                            </div>
                            <div className="col-lg-4 time">
                              <Form.Item
                                className="form-items"
                                name="repeatDays"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select Day!",
                                  },
                                ]}
                              >
                                <Select
                                  getPopupContainer={(triggerNode) =>
                                    triggerNode.parentNode
                                  }
                                  placeholder="Select Day"
                                  optionFilterProp="children"
                                  onChange={(value) => setDay(value)}
                                >
                                  {weekly.map((client) => {
                                    return (
                                      <Option value={client.key}>
                                        {client.label}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="col-lg-2 time line-col">for</div>
                            <div className="col-lg-4">
                              <Form.Item
                                className="form-items"
                                name="meetingDuration"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select Duration!",
                                  },
                                ]}
                              >
                                <Select
                                  getPopupContainer={(triggerNode) =>
                                    triggerNode.parentNode
                                  }
                                  placeholder="Select Duration"
                                  optionFilterProp="children"
                                >
                                  {weekly1.map((client) => {
                                    return (
                                      <Option value={client.key}>
                                        {client.label}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </Row>
                        )}
                      </TabPane>
                      <TabPane className="event-wrap" tab="Bi-Weekly" key="2">
                        {key == 2 && (
                          <Row className="pt-15 align-item-center">
                            <div className="col-lg-2 time line-col">
                              On Every
                            </div>
                            <div className="col-lg-4 time">
                              <Form.Item
                                className="form-items"
                                name="repeatDays"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select Day!",
                                  },
                                ]}
                              >
                                <Select
                                  getPopupContainer={(triggerNode) =>
                                    triggerNode.parentNode
                                  }
                                  placeholder="Select Day"
                                  optionFilterProp="children"
                                >
                                  {weekly.map((client) => {
                                    return (
                                      <Option value={client.key}>
                                        {client.label}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="col-lg-2 time line-col">for</div>
                            <div className="col-lg-4">
                              <Form.Item
                                className="form-items"
                                name="meetingDuration"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select Duration!",
                                  },
                                ]}
                              >
                                <Select
                                  getPopupContainer={(triggerNode) =>
                                    triggerNode.parentNode
                                  }
                                  placeholder="Select Duration"
                                  optionFilterProp="children"
                                >
                                  {weekly1.map((client) => {
                                    return (
                                      <Option value={client.key}>
                                        {client.label}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </Row>
                        )}
                      </TabPane>
                      <TabPane className="event-wrap" tab="Monthly" key="3">
                        {key == 3 && (
                          <Row className="pt-15 align-item-center">
                            <div className="col-lg-2 time line-col">On the</div>
                            <div className="col-lg-4 time">
                              <Form.Item
                                className="form-items"
                                name="repeatMonthDay"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select Day of Month!",
                                  },
                                ]}
                              >
                                <Select
                                  getPopupContainer={(triggerNode) =>
                                    triggerNode.parentNode
                                  }
                                  placeholder="Select Day"
                                  optionFilterProp="children"
                                  onChange={(value) => setDayOfMonth(value)}
                                >
                                  {monthly.map((client) => {
                                    return (
                                      <Option value={client.key}>
                                        {client.label}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="col-lg-2 time line-col">of every</div>
                            <div className="col-lg-4 time">
                              <Form.Item
                                className="form-items"
                                name="noOfMonths"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select Month!",
                                  },
                                ]}
                              >
                                <Select
                                  getPopupContainer={(triggerNode) =>
                                    triggerNode.parentNode
                                  }
                                  placeholder="Select Month"
                                  optionFilterProp="children"
                                  onChange={(value) => setMonth(value)}
                                >
                                  {monthly1.map((client) => {
                                    return (
                                      <Option value={client.key}>
                                        {client.label}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </Row>
                        )}
                      </TabPane>
                    </Tabs>
                  </div>
                </div>
              {/* </div>
            </div>
          </Content>
        </Layout> */}
      </>
    );
  };

  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(1);
  
  const [time, setTime] = useState();
  const [day, setDay] = useState();
  const [sendDate, setSendDate] = useState();
  const [psttime, setPstTime] = useState(null);
  const [csttime, setCstTime] = useState(null);
  const [isttime, setIstTime] = useState(null);
  
  const [selectedClient, setSelectedClient] = useState();
  const [key, setKey] = useState("1");
  const [month, setMonth] = useState();
  const [dayOfMonth, setDayOfMonth] = useState();
  const [ModalVisible, setModalVisible] = useState(false);
  const [lastFieldChanged, setLastFieldChanged] = useState();
  
  const [notes, setNotes] = useState();
  const [totalNotes, setTotalNotes] = useState();
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    const data = { limit: 10000, skip, clientID: clientid, notesType: 5 };

    setIsLoading(true);
    axios
      .post(`${API_BASE}v1/clientForm/getClientNotesList`, data)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          setNotes(
            res.data.data.list.map((data) => {
              return { ...data, key: data._id };
            })
          );
          setTotalNotes(res.data.data.list.length);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [skip, excelInfoVisible]);

  const onChange = (e) => {
    //console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const handleFormSubmit = (data) => {
    setIsLoading(true);
    if (type === "add") {
      let [minute, hour] = [time.get("minute"), time.get("hour")];
      let sendData = {
        clientID: data?.clientID,
        isRecurring: value == 1 ? true : false,
        recurringTerms: value == 1 ? key : "",
        repeatDays:
          value == 1 && (key == 1 || key == 2)
            ? [Number(data?.repeatDays)]
            : [],
        repeatMonthDay: value == 1 && key == 3 ? data?.repeatMonthDay : "",
        repeatMonth: value == 1 && key == 3 ? Number(data?.noOfMonths) + 1 : "",
        meetingDuration: key == 1 || key == 2 ? data?.meetingDuration : "",
        isRecurringStop: value == 1 ? false : true,
        meetingDate:
          value == 2
            ? moment(data?.meetingDate).set({ hour, minute }).utc().format()
            : sendDate,
      };
      //console.log("senddata", sendData);
      dispatch(scheduleMeeting(sendData))
        .then((res) => {
          setIsLoading(false);
          if (res.payload.statusCode === 200) {
            form.resetFields();
            //console.log(res.payload.data);
            message.success(res.payload.message);
            setTime();
            setPstTime(null);
            setCstTime(null);
            setIstTime(null);
            setValue(1);
            setKey("1");
          } else {
            message.error(res.payload.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
    if (type === "edit") {
      let [minute, hour] = [time.get("minute"), time.get("hour")];
      let sendData = {
        meetingID: meetingId.id,
        clientID: data?.clientID,
        isRecurring: value == 1 ? true : false,
        recurringTerms: value == 1 ? key : "",
        repeatDays:
          value == 1 && (key == 1 || key == 2)
            ? [Number(data?.repeatDays)]
            : [],
        repeatMonthDay: value == 1 && key == 3 ? data?.repeatMonthDay : "",
        repeatMonth: value == 1 && key == 3 ? Number(data?.noOfMonths) + 1 : "",
        meetingDuration: key == 1 || key == 2 ? data?.meetingDuration : "",
        isRecurringStop: value == 1 ? false : true,
        meetingDate:
          value == 2
            ? moment(data?.meetingDate).set({ hour, minute }).utc().format()
            : sendDate,
      };
      //console.log("senddata", sendData);
      dispatch(updateMeeting(sendData))
        .then((res) => {
          setIsLoading(false);
          if (res.payload.statusCode === 200) {
            // form.resetFields()
            //console.log(res.payload.data);
            message.success(res.payload.message);
            navigate("/schedule_meeting/view_meeting");
            // setTime()
            // setPstTime(null)
            // setCstTime(null)
            // setIstTime(null)
            // setValue(1)
            // setKey("1")
            // setApiToggle(!apiToggle);
          } else {
            message.error(res.payload.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };
  const handleTime = (time, value) => {
    setTime(time);
    setLastFieldChanged("time")
  };

  useEffect(() => {
    if (meetingData?.clientID) {
      dispatch(
        getListOfUpcommingMeeting({
          clientID: meetingData?.clientID,
          // startDate: moment().startOf('week').format("YYYY-MM-DD"),
          // endDate: moment().endOf('week').format("YYYY-MM-DD")
        })
      )
        .then((res) => {
          if (res.payload.statusCode == 200) {
            setUpcomingMeeting(res.payload.data);
          }
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  }, [meetingData?.clientID, apiToggle]);

  useEffect(() => {
    if(lastFieldChanged === "time"){
      //console.log("time")
      if(!psttime && !csttime && !isttime || psttime && csttime && isttime){
        //console.log("hello from time")
      if(time){
        
        //console.log("pst", moment(time.format()).utc())
        // console.log("pst", moment(time.format()).utcOffset(-180))
        // console.log("cst", moment(time.format()).utcOffset(-60))
        // console.log("ist", moment(time.format()).utcOffset(630))
        const date = time.format();
        
      (key == 1 || key == 2) &&
        day &&
        setSendDate(moment(date).day(day).utc().format());
      key == 3 &&
        dayOfMonth &&
        month &&
        setSendDate(moment(date).date(dayOfMonth).month(month).utc().format());
      //setPstTime(moment.tz(date, "America/Los_Angeles"));
      //setCstTime(moment.tz(date, "America/Chicago"));
      //setIstTime(moment.tz(date, "Asia/Kolkata"));
      
      setPstTime(moment(time.format()).utc().utcOffset(150))
      setCstTime(moment(time.format()).utc().utcOffset(270))
      setIstTime(moment(time.format()).utc().utcOffset(960))
    }  else {
      console.log("null")
      setPstTime(null);
      setCstTime(null);
      setIstTime(null);
    } 
  }}
     
  }, [time, day, key, dayOfMonth, month]);

  useEffect(() => {
    if(lastFieldChanged === "psttime"){
      if(time && csttime && isttime || !time && !csttime && !isttime){
        if (psttime) {
          //console.log("hello from psttime")
          //moment.tz.add('America/Los_Angeles')

          //moment(psttime).tz("America/Los_Angeles");
          // console.log("est", moment(psttime.format()).utcOffset(180))
          // console.log("cst", moment(psttime.format()).utcOffset(120))
          // console.log("ist", moment(psttime.format()).utcOffset(810))
          //console.log(moment.tz(psttime,"America/Los_Angeles"))
          //console.log(moment.tz(moment(psttime), "America/Los_Angeles"))
          //console.log(moment.tz(moment(psttime), "America/New_York"))
          
          // console.log(moment(moment(psttime).utc().format()).tz("America/New_York").format())
          // console.log(moment.tz(psttime,"America/New_York").format())
          // console.log(moment.tz(psttime,'America/New_York').format())

          const date = psttime.format();
          (key == 1 || key == 2) &&
            day &&
            setSendDate(moment(date).day(day).utc().format());
          key == 3 &&
            dayOfMonth &&
            month &&
            setSendDate(moment(date).date(dayOfMonth).month(month).utc().format());
          //console.log(moment.tz(date, "America/New_York"))
          // setTime(moment.tz(date, "America/New_York"));
          // setCstTime(moment.tz(date, "America/Chicago"));
          // setIstTime(moment.tz(date, "Asia/Kolkata"));    
          setTime(moment(psttime.format()).utc().utcOffset(510))
          setCstTime(moment(psttime.format()).utc().utcOffset(450))
          setIstTime(moment(psttime.format()).utc().utcOffset(1140))      
        } 
        else {
          console.log("null")
          setTime(null)
          setCstTime(null);
          setIstTime(null);
        }
      } 
    } 
  }, [psttime, day, key, dayOfMonth, month]);

  useEffect(() => {
    if(lastFieldChanged === "csttime"){
      if(time && psttime && isttime || !time && !psttime && !isttime){
    if (csttime) {
      //console.log("hello from csttime")
      // console.log("est", moment(csttime.format()).utcOffset(60))
      // console.log("pst", moment(csttime.format()).utcOffset(-120))
      // console.log("ist", moment(csttime.format()).utcOffset(750))
      const date = csttime.format();
    
      (key == 1 || key == 2) &&
        day &&
        setSendDate(moment(date).day(day).utc().format());
      key == 3 &&
        dayOfMonth &&
        month &&
        setSendDate(moment(date).date(dayOfMonth).month(month).utc().format());
      // setPstTime(moment.tz(date, "America/Los_Angeles"));
      // setTime(moment.tz(date, "America/New_York"));
      // setIstTime(moment.tz(date, "Asia/Kolkata"));
      setTime(moment(csttime.format()).utc().utcOffset(390))
      setPstTime(moment(csttime.format()).utc().utcOffset(210))
      setIstTime(moment(csttime.format()).utc().utcOffset(1020))
    } else {
      console.log("null")
      setPstTime(null);
      //setCstTime(null);
      setTime(null)
      setIstTime(null);
    }
  }}
  }, [csttime, day, key, dayOfMonth, month]);

  useEffect(() => { 
    if(lastFieldChanged === "isttime"){
      if(time && csttime && psttime || !time && !csttime && !psttime){
    if (isttime) {
      //console.log("hello from isttime")
      // console.log("est", moment(isttime.format()).utcOffset(-630))
      // console.log("pst", moment(isttime.format()).utcOffset(-810))
      // console.log("cst", moment(isttime.format()).utcOffset(-750))
      const date = isttime.format();
      
      (key == 1 || key == 2) &&
        day &&
        setSendDate(moment(date).day(day).utc().format());
      key == 3 &&
        dayOfMonth &&
        month &&
        setSendDate(moment(date).date(dayOfMonth).month(month).utc().format());
      // setPstTime(moment.tz(date, "America/Los_Angeles"));
      // setCstTime(moment.tz(date, "America/Chicago"));
      // setTime(moment.tz(date, "America/New_York"));
      setTime(moment(isttime.format()).utc().utcOffset(-300))
      setPstTime(moment(isttime.format()).utc().utcOffset(-480))
      setCstTime(moment(isttime.format()).utc().utcOffset(-360))
    } else {
      setPstTime(null);
      setCstTime(null);
      setTime(null)
      console.log("null")
      //setIstTime(null);
    }
  }}
  }, [isttime, day, key, dayOfMonth, month]);


  useEffect(() => {
    if (meetingId?.id && type == "edit" && meetingData) {
      form.setFieldsValue({
        clientID: meetingData?.clientID,
        meetingDate: moment(meetingData?.meetingDate).tz("America/New_York"),
        time: moment(meetingData?.meetingDate).tz("America/New_York"),
        meetingDuration: meetingData?.meetingDuration
          ? JSON.stringify(meetingData?.meetingDuration)
          : null,
        repeatDays: meetingData?.repeatDays?.length
          ? JSON.stringify(meetingData?.repeatDays[0])
          : null,
        repeatMonthDay: meetingData?.repeatMonthDay
          ? JSON.stringify(meetingData?.repeatMonthDay)
          : null,
        noOfMonths: meetingData?.repeatMonth
          ? JSON.stringify(meetingData?.repeatMonth - 1)
          : null,
      });
      setValue(meetingData?.isRecurring ? 1 : 2);
      setTime(moment(meetingData?.meetingDate).tz("America/New_York"));
      setKey(JSON.stringify(meetingData?.recurringTerms));
      setDay(
        meetingData?.repeatDays?.length &&
          JSON.stringify(meetingData?.repeatDays[0])
      );
      setDayOfMonth(JSON.stringify(meetingData?.repeatMonthDay));
      setMonth(JSON.stringify(meetingData?.repeatMonth - 1));
    }
  }, [meetingId?.id, type, meetingData, form]);

  const handleOk = () => {
    setIsLoading(true);
    dispatch(stopRecurringMeeting({ meetingID: meetingId.id }))
      .then((res) => {
        setIsLoading(false);
        if (res.payload.statusCode === 200) {
          message.success(res.payload.message);
          setModalVisible(false);
          navigate("/schedule_meeting/view_meeting");
          // setApiToggle(!apiToggle);
        } else {
          message.error(res.payload.message);
          setModalVisible(false);
        }
      })
      .catch((err) => {
        setIsLoading(true);
        console.log(err);
        setModalVisible(false);
      });
  };

  const handleCancel = () => {
    setModalVisible(false);
  };
  
  const columns2 = [
    {
      title: "Change Request Notes",
      dataIndex: "notes",
      key: "Notes",
    },
  ];

  return (
    <>
      {isLoading && <Spin />}
      <div className="content-wrap">
        <div className="box">
          <div className="box-form">
            <Form
              className="sectionadd-form"
              onFinish={handleFormSubmit}
              form={form}
              
            >
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <Form.Item
                      name="clientID"
                      rules={[
                        {
                          required: true,
                          message: "Please select Client!",
                        },
                      ]}
                    >
                      <Select
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        disabled={type === "edit" ? true : false}
                        showSearch
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={(event) => {
                          { setSelectedClient(event)} 
                          
                          //{setSelectedClient(event.target.value);}
                          // dispatch(
                          //   getListOfUpcommingMeeting({
                          //     clientId: event.target.value,
                          //   })
                          // );
                        }}
                      > 
                      
                        {clientList?.map((client) => {
                          return (
                            <Option value={client._id} key={client._id}>
                              {client.clientName}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mb-15">
                  <Radio.Group
                    disabled={type === "edit" ? true : false}
                    onChange={onChange}
                    value={value}
                  >
                    <Radio value={1}>Recurring Meeting</Radio>
                    <Radio value={2}>Single Meeting Schedule</Radio>
                  </Radio.Group>
                </div>
              </div>

              <div className="row mb-15">
                <div className="col-lg-2 col-sm-3 time">
                  <label className="label-title">EST</label>
                  <span>
                    {console.log(time)}
                    <Form.Item
                      name="time"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please select Time!",
                      //   },
                      // ]}
                    > 
                      <TimePicker
                        // defaultValue={moment("12:08", format)}
                        value={time ? moment(time, "HH:mm") : null}
                        onChange={handleTime}
                        format={format}
                      />
                    </Form.Item>
                  </span>
                </div>
                <div className="col-lg-2 col-sm-3 time">
                  <label className="label-title">PST</label>
                  <span>
                    <TimePicker
                      value={psttime ? moment(psttime, "HH:mm") : null}
                      format={format}
                      onChange={(time) => {console.log(time);setPstTime(time); setLastFieldChanged("psttime")}}
                    />
                  </span>
                </div>
                <div className="col-lg-2 col-sm-3 time">
                  <label className="label-title">CST</label>
                  <span>
                    <TimePicker
                      value={csttime ? moment(csttime, "HH:mm") : null}
                      format={format}
                      onChange={(time) => {setCstTime(time); setLastFieldChanged("csttime")}}
                    />
                  </span>
                </div>
                <div className="col-lg-2 col-sm-3 col-xs-6">
                  <label className="label-title">IST</label>
                  <span>
                    <TimePicker
                      value={isttime ? moment(isttime, "HH:mm") : null}
                      format={format}
                      onChange={(time) => {setIstTime(time); setLastFieldChanged("isttime")}}
                    />
                  </span>
                </div>
              </div>
              <div>{renderContent(value)}</div>
              <Row justify="right" className="mt-15 mb-15">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-primary"
                >
                  Submit
                </Button>
                {type === "edit" &&
                  value == 1 &&
                  meetingData?.isRecurring == true &&
                  meetingData?.isRecurringStop == false && (
                    <Button
                      type="primary"
                      htmlType="button"
                      className="btn-primary"
                      onClick={() => setModalVisible(true)}
                    >
                      Stop Recurring Meeting
                    </Button>
                  )}
              </Row>
            </Form>

            {/* below calender is creating for manager when manager page is loggged in then you can only see this datepicker in l.h.s  */}
            {/* <Calender /> */}
          </div>
          <div className="box-aside">
            <Row>
              <div className="col-lg-12">
                {upcomingMeeting?.list?.map((val) => (
                  <Timeline>
                    <h4>{moment(val?.date).format("DD/MM/YYYY")}</h4>
                    {val?.meetingArr.length &&
                      val?.meetingArr.map((val1, i) => (
                        <Timeline.Item
                          color={color[i % color.length].key}
                        >{`Call with ${val1?.clientName} ${moment(
                          val1?.meetingDate
                        ).format("hh:mm a")}`}</Timeline.Item>
                      ))}
                  </Timeline>
                ))}
              </div>
            </Row>
          </div>
        </div>
        {ModalVisible ? (
          <Modal
            visible={ModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            centered={true}
            className="common-modal edit-modal"
          >
            <div className="modal-body">
              <div className="desc">
                {/* <span className="icon"><ExclamationCircleOutlined /></span> */}
                <h2 className="title">
                  Are you sure you want to stop Recurring meeting ?
                </h2>
                <p>Your action can't be undone</p>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>

      <Row>
          <div className="col-lg-12">
            <div className="common-table">
              <Table
                bordered
                dataSource={notes}
                columns={columns2}
                scroll={{ x: 900 }}
                loading={isLoading}
                pagination={{
                  current: page,
                  defaultPageSize: limit,
                  total: totalNotes,
                  showTotal: (total) => `Total ${total} records`,
                  onChange: (page, pagesize) => {
                    setPage(page);
                    setSkip((page - 1) * pagesize);
                  },
                }}
              />
            </div>
          </div>
        </Row>

        <AddNote
          notesType={5}
          setexcelInfoVisible={setexcelInfoVisible}
          excelInfoVisible={excelInfoVisible}
        />
    </>
  );
};

export default CommonMeeting;
