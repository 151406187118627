import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Space, Table } from "antd";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_BASE } from "../../../constants/api";
import { fillClientForm } from "../../../Redux/clientFormSlice";

const TClient = (prop) => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState();
  const [isLoading, setIsLoading] = useState(false);
  
  const [show, setShow] = useState(true);
  const [pageType, setPageType] = useState("ADD");
  const [pageType1, setPageType1] = useState("Add");
  const [teamId, setTeamId] = useState();
  const [previousDataExist, setPreviousDataExist] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    setPageType("EDIT")
    const data = {
      clientID: clientid,
      teamMemberType: 2,
    };

    setIsLoading(true);
    axios
      .post(`${API_BASE}v1/team-member/getAssignTeamMemberList`, data)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          console.log(res?.data?.data?.list?.[0]._id)
          if(res?.data.data.list?.[0]?.clientTeamMemberDetails?.length){
            setPreviousDataExist(true)
            setPageType1("Edit")
            setTeamId(res?.data?.data?.list?.[0]._id)
            setDataSource(res?.data.data.list?.[0]?.clientTeamMemberDetails);
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    setPageType("EDIT")
    const data = {
      clientID: clientid,
      teamMemberType: 1,
    };

    setIsLoading(true);
    axios
      .post(`${API_BASE}v1/team-member/getAssignTeamMemberList`, data)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          if(res?.data?.data?.list?.[0]?.teamMemberList?.length){
            setTeamId(res?.data?.data?.list?.[0]._id)
            setPreviousDataExist(true)
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);


  useEffect(() => {
    if (dataSource?.length) {
      form.setFieldsValue({
        users: dataSource,
      });
    }
  }, [dataSource]);

  const columns = [
    {
      title: "Person Name",
      dataIndex: "person",
      key: "person",
      sorter: {
        compare: (a, b) => a.fname - b.fname,
        multiple: 3,
      },
    },
    {
      title: "Phone number",
      dataIndex: "phone",
      key: "phone",
      sorter: {
        compare: (a, b) => a.fname - b.fname,
        multiple: 3,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: {
        compare: (a, b) => a.fname - b.fname,
        multiple: 3,
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "Role",
      sorter: {
        compare: (a, b) => a.fname - b.fname,
        multiple: 3,
      },
    },
  ];

  
  const onFinish = (values) => {  
    setDataSource(
      values.users?.map((data, index) => {
        return {
          ...data,
          key: index,
        };
      })
    );
    let clientID = localStorage.getItem("clientID");
    const data = {
      clientID,
      clientTeamIDS: dataSource
    }
    //console.log(teamId);
    if(!previousDataExist){
      //console.log("I am from add")
      setIsLoading(true);
      axios.post(`${API_BASE}v1/team-member/addTeamMember`, data)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          message.success(res.data.message);
          if (!prop.done) {
            prop.setKey1("2");
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });  
    } else {
      //console.log("I am from edit")
      setIsLoading(true);
      axios.post(`${API_BASE}v1/team-member/updateTeamMember`, {...data, _id:teamId})
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          message.success(res.data.message);
          if (!prop.done) {
            prop.setKey1("2");
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
    }
  };

  const handleValues = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        setDataSource(
          values?.users
            ? values?.users.map((data, index) => {
                return { ...data, key: index };
              })
            : []
        );
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };
  return (
    <Form
      ref={prop.refs}
      form={form}
      name="basic12"
      onFinish={onFinish}
      autoComplete="off"
    >
      { ((pageType==="ADD") || pageType==="EDIT" && !show) ?
      <>
      <Form.List name="users">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{
                  display: "flex",
                  marginBottom: 8,
                }}
                align="baseline"
              >
                <Form.Item
                  {...restField}
                  name={[name, "person"]}
                  rules={[
                    {
                      required: true,
                      message: "Missing person name",
                    },
                  ]}
                >
                  <Input placeholder="Person Name" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "phone"]}
                  rules={[
                    {
                      required: true,
                      message: "Missing phone number",
                    },
                  ]}
                >
                  <Input placeholder="Phone Number" type="number" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "email"]}
                  rules={[
                    {
                      type: "email",
                      message: "email is not valid",
                    },
                    {
                      required: true,
                      message: "Missing email",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "role"]}
                  rules={[
                    {
                      required: true,
                      message: "Missing role",
                    },
                  ]}
                >
                  <Input placeholder="Role" />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Member
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item>
        <Button type="primary" htmlType="button" onClick={handleValues}>
          Create Team
        </Button>
      </Form.Item>
      </>
      : null }

      {show && pageType === "EDIT" ?  
      <Button type="primary" htmlType="button" onClick={() => setShow(false)}>
        Update Team
      </Button>
      : null }            

      <div className="common-table client-table">
        <Table
          bordered
          dataSource={dataSource}
          columns={columns}
          scroll={{ x: 800 }}
          pagination={false}
        />
      </div>
    </Form>
  );
};

export default TClient;
