// import { doLogout } from "../actions/auth";
import axios from "axios";
import STORAGE_KEYS from "./keys";

//To concate the path for the public folder
export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export const axiosInterceptor = () => {
  const token = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN)
    ? localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN)
    : document?.cookie?.split?.(";")?.[0]?.split?.("=")?.[1];

  //console.log(token);
  axios.defaults.headers.common["Authorization"] = token ? `${token}` : null;

  axios.interceptors.request.use(null, (error) => {
    if (error.response) {
      if (error.response?.status === 401) {
        return Promise.reject(error);
      } else return Promise.reject(error);
    } else if (error.request) {
      let err = {
        response: {
          data: {
            message: "Something went wrong,Please try again later!!!",
          },
        },
      };
      return Promise.reject(err);
    }
  });

  axios.interceptors.response.use(null, (error) => {
    if (error.response) {
      if (error.response?.status === 401) {
        console.log(error)
        axios.get('https://jsonplaceholder.typicode.com/todos/1')
        .then(res => {
          console.log(res)
        })
        .catch(err => {
          console.log(err)
        })
        return Promise.reject(error);
      } else return Promise.reject(error);
    } else if (error.request) {
      let err = {
        response: {
          data: {
            message: "Something went wrong,Please try again later!!!",
          },
        },
      };
      return Promise.reject(err);
    }
  });
};
