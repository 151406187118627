import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import routeMap from "../routes";

const Login = lazy(() => import("../pages/Login"));
const ForogtPassword = lazy(() => import("../pages/ForogtPassword"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));
const SetFirstPassword = lazy(() => import("../pages/SetFirstPassword"));
const Welcome = lazy(() => import("../pages/Welcome"));
const FaqHome = lazy(() => import("../pages/Faq"));

const Routing = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForogtPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/set-password/false" element={<SetFirstPassword />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/faq" element={<FaqHome />} />
      ...{routeMap}
      <Route path="/" element={<Navigate replace to="/welcome" />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default Routing;
