import React, { useEffect, useState } from "react";
import "./profiledetail.scss";
import { Layout, Input, Button, Form, Row, message } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/common/Breadcrumb";
//import TextArea from "antd/lib/input/TextArea";
import { editUser, getUser } from "../../Redux/userSlice";
import { useDispatch } from "react-redux";
import STORAGE_KEYS from "../../keys";
import Loading from "../../components/common/loader";

const ProfileDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
      
  const [form] = Form.useForm();
  
  const [user, setUser] = useState();
  const [editProfile, setEditProfile] = useState(false);
  const [buttonText, setButtonText] = useState("Edit Profile");
      
  let userId = localStorage.getItem("userID")
    ? localStorage.getItem("userID")  
    : document?.cookie?.split?.(";")?.[1]?.split?.("=")?.[1];
  
  const onFinish = (values) => {
    if (!editProfile) {
      setEditProfile(true);
      setButtonText("Save Profile");
      message.info("you can now edit profile");
    } else {
      //console.log(values);
      dispatch(editUser({ ...values, userId: userId }))
        .then((res) => {
          if (res.payload.statusCode === 200) {
            localStorage.setItem(
              "userName",
              values.firstName + " " + values.lastName
            );
            message.success(res.payload.message);
            navigate("/client/add_BdUser");
          } else {
            message.error(res.payload.message);
          }
        }) 
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    dispatch(getUser(userId))
      .then((res) => {
        //console.log(res.payload.data);
        if (res.payload.statusCode === 200) {
          setUser(res.payload.data);
        } else {
          message.error(res.payload.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        email: user.email,
      });
    }
  }, [user]);

  return (
    <Loading>
      <Layout className="site-layout-background">
        <Content className="admin-webpanel-content">
          <Breadcrumbs content="Edit profile" />
          <div className="without-layout">
            <div className="right-panel">
              <Form
                className="sectionadd-form"
                form={form}
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
              >
                <div className="filter-wrap mb-25">
                  <h2 className="title">Profile Details</h2>
                  <Row>
                    <Button
                      type="primary"
                      htmlType="button"
                      className="btn-primary"
                    >
                      <Link to="/profile/changepassword">Change Password</Link>
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btn-primary"
                    >
                      {buttonText}
                    </Button>
                  </Row>
                </div>
                <div className="event-wrap">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <Form.Item
                          name="firstName"
                          rules={[
                            {
                              required: editProfile ? true : false,
                              message: "Please enter your name!",
                            },
                            () => ({
                              validator(_, value) {
                                if (
                                  editProfile &&
                                  value &&
                                  value.search(/^[a-zA-Z]+$/) === -1
                                ) {
                                  return Promise.reject("Provide valid name");
                                }
                                if (
                                  editProfile &&
                                  value &&
                                  (value.length < 2 || value.length > 50)
                                ) {
                                  return Promise.reject(
                                    "name must contain at least 2 character"
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input disabled={!editProfile} placeholder="First Name" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <Form.Item
                          name="lastName"
                          rules={[
                            {
                              required: editProfile ? true : false,
                              message: "Please enter your name!",
                            },
                            () => ({
                              validator(_, value) {
                                if (
                                  editProfile &&
                                  value &&
                                  value.search(/^[a-zA-Z]+$/) === -1
                                ) {
                                  return Promise.reject("Provide valid name");
                                }
                                if (
                                  editProfile &&
                                  value &&
                                  (value.length < 2 || value.length > 50)
                                ) {
                                  return Promise.reject(
                                    "name must contain at least 2 character"
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input disabled={!editProfile} placeholder="Last Name" />
                        </Form.Item>
                      </div>
                    </div>
                    {/* <div className="col-12">
                    <div className="form-group">
                      <Input
                        placeholder="Customer Address 1"
                        value={user?.address?.fullAddress}
                        onChange={(event) => {
                          setFullAddress(event.target.value);
                          setValues({
                            ...values,
                            address: {
                              fullAddress: fullAddress,
                              addressTwo: addressTwo,
                              zipcode: zipcode,
                              city: city,
                              state: state,
                              country: country,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <Input
                        placeholder="Customer Address 2"
                        value={user?.address?.addressTwo}
                        onChange={(event) => {
                          setAddressTwo(event.target.value);
                          setValues({
                            ...values,
                            address: {
                              fullAddress: fullAddress,
                              addressTwo: addressTwo,
                              zipcode: zipcode,
                              city: city,
                              state: state,
                              country: country,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <Select
                        defaultValue="USA"
                        value={user?.address?.country}
                        onChange={(event) => {
                          setCountry(event.target.value);
                          setValues({
                            ...values,
                            address: {
                              fullAddress: fullAddress,
                              addressTwo: addressTwo,
                              zipcode: zipcode,
                              city: city,
                              state: state,
                              country: country,
                            },
                          });
                        }}
                      >
                        <Option value="USA">
                          USA
                        </Option>
                        <Option value="India">India</Option>
                        <Option value="Canada">Canada</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <Select
                        defaultValue="California"
                        value={user?.address?.state}
                        onChange={(event) => {
                          setState(event.target.value);
                          setValues({
                            ...values,
                            address: {
                              fullAddress: fullAddress,
                              addressTwo: addressTwo,
                              zipcode: zipcode,
                              city: city,
                              state: state,
                              country: country,
                            },
                          });
                        }}
                      >
                        <Option value="California">
                          California
                        </Option>
                        <Option value="Gujarat">Gujarat</Option>
                        <Option value="NewYork">NewYork</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <Select
                        defaultValue="Los Angeles"
                        value={user?.address?.city}
                        onChange={(event) => {
                          setCity(event.target.value);
                          setValues({
                            ...values,
                            address: {
                              fullAddress: fullAddress,
                              addressTwo: addressTwo,
                              zipcode: zipcode,
                              city: city,
                              state: state,
                              country: country,
                            },
                          });
                        }}
                      >
                        <Option value="Los Angeles">
                          Los Angeles
                        </Option>
                        <Option value="NewYork">NewYork</Option>
                        <Option value="Delhi">Delhi</Option>
                      </Select>
                    </div>
                  </div> */}
                    {/* <div className="col-lg-6">
                    <div className="form-group">
                      <Input
                        defaultValue="90001"
                        value={user?.address?.zipcode}
                        onChange={(event) => {
                          setZipcode(event.target.value);
                          setValues({
                            ...values,
                            address: {
                              fullAddress: fullAddress,
                              addressTwo: addressTwo,
                              zipcode: zipcode,
                              city: city,
                              state: state,
                              country: country,
                            },
                          });
                        }}
                      ></Input>
                    </div>
                  </div> */}
                    <div className="col-lg-6">
                      <div className="form-group">
                        <Form.Item
                          name="email"
                        // rules={[
                        //   { type: "email" },
                        //   {
                        //     required: editProfile ? true : false,
                        //     message: "Please enter your email!",
                        //   },
                        // ]}
                        >
                          <Input disabled={true} placeholder="Email" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <Form.Item
                          name="phoneNumber"
                          rules={[
                            { whitespace: editProfile ? true : false },
                            {
                              required: editProfile ? true : false,
                              message: "Please enter your phone number!",
                            },
                            () => ({
                              validator(_, value) {
                                if (
                                  editProfile &&
                                  value &&
                                  value.search(/^\+?\d+$/) === -1
                                ) {
                                  return Promise.reject(
                                    "Provide valid Phone number"
                                  );
                                }
                                if (
                                  editProfile &&
                                  value &&
                                  (value.length < 10 || value.length > 16)
                                ) {
                                  return Promise.reject(
                                    "Provide valid Phone number"
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            disabled={!editProfile}
                            placeholder="Phone Number"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>

        </Content>
      </Layout>
    </Loading>
  );
};

export default ProfileDetail;
