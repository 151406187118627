import React, { useState ,useEffect} from "react";
import "./header.scss";
import { toAbsoluteUrl } from "../../../utils";
import { Link, useNavigate } from "react-router-dom";
import { Menu, Dropdown, message, Button, Modal, Space } from "antd";
import { API_BASE } from "../../../constants/api";
import axios from "axios";
import Help from "../../../SVG/Help";
import Schedule from "../../../SVG/Schedule";

const Header = () => {
  let userName = localStorage.getItem("userName");
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    const userId = localStorage.getItem("user");
    const accessToken = localStorage.getItem("access_token");
    const data = { userId, accessToken };

    axios
      .post(`${API_BASE}v1/user/logout`, data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          localStorage.clear();
          message.success(res.data.message);
          navigate("/login");
        } else {
          message.success(res.data.message);
        }
        setIsModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
        setIsModalOpen(false);
      });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/profile">My Profile</Link>
      </Menu.Item>
      <Menu.Item key="2" onClick={showModal}>
        <a>Logout</a>
      </Menu.Item>
    </Menu>
  );

    // scroll 
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);

  return (
    <>
      <div className={`header-wrap ${scroll ? "header-bg" : ""}`}>
        <div className="logo">
          <Link className="header-logo" to="/client/add_BdUser">
            <img
              src={toAbsoluteUrl("/assets/images/logo.png")}
              className="d-sm-none"
              alt="logo"
            />
            <img
              src={toAbsoluteUrl("/assets/images/hedaer-icon.png")}
              className="d-none d-sm-block"
              alt="logo"
            />
          </Link>
        </div>

        <div className="header-right">
          <div className="hedaer-title">
            <h2 className="main-title">Client Onbording</h2>
          </div>

          <div className="help-btn">
            <Button type="primary" htmlType="button" className="btn-primary">
              <Link to="/schedule_meeting">Schedule Meeting<Schedule /></Link>
            </Button>
            <Button type="primary" htmlType="button" className="btn-primary">
              <Link to="/faqs">Need Help<Help/></Link>
            </Button>
            <Dropdown overlay={menu} trigger={["click"]}>
              <span className="person-chat" onClick={(e) => e.preventDefault()}>
                <img
                  src={toAbsoluteUrl("/assets/images/profile.png")}
                  //src={<UserOutlined />}
                  alt="person"
                />
                {userName}
              </span>
            </Dropdown>
          </div>

          <Modal
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            className="logout-modal"
            width={450}
            centered
            footer={[
              <Space size={10}>
                <Button type="secondary btn-sm" onClick={handleCancel}>
                  No
                </Button>
                <Button type="primary btn-sm" onClick={handleOk}>
                  Yes
                </Button>
              </Space>,
            ]}
          >
            <p>Are you sure want logout?</p>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Header;
