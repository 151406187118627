import React, { useEffect, useState } from "react";

import { Form, message, Radio } from "antd";
import { useDispatch } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";

import {
  fillClientForm,
  getClientDetail,
} from "./../../../../Redux/clientFormSlice";

const Job = (prop) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [jobData, setJobData] = useState();
  const [update, setUpdate] = useState(true);

  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    if (clientid) {
      setIsLoading(true);
      dispatch(getClientDetail(clientid))
        .then((res) => {
          setIsLoading(false);
          if (res.payload.statusCode === 200) {
            setJobData(res.payload.data);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, [update]);

  useEffect(() => {
    if (jobData) {
      form.setFieldsValue({
        isTrialJob: jobData.isTrialJob,
      });
    }
  }, [jobData]);

  const onFinish = (values) => {
    let userID = localStorage.getItem("userID");
    const data = {
      ...values,
      isAccess: true,
      userID,
      mainStepNo: 3,
      subStepNo: 0,
    };

    setIsLoading(true);
    dispatch(fillClientForm(data))
      .then((res) => {
        setIsLoading(false);
        if (res.payload.statusCode === 200) {
          message.success(res.payload.message);
          if (!prop.done) {
            prop.setCurrent((prev) => prev + 1);
          } else {
            navigate("/client");
          }
        } else {
          message.error(res.payload.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleChange = (changedFields) => {
    let abc = prop.steps.concat();
    if (changedFields[0].value === false) {
      abc = [...abc.slice(0, 4), ...abc.slice(5, abc.length)];
    }

    let userID = localStorage.getItem("userID");
    const data = {
      isTrialJob: changedFields[0].value,
      isAccess: true,
      userID,
      mainStepNo: 3,
      subStepNo: 0,
    };
    //console.log(data);

    setIsLoading(true);
    dispatch(fillClientForm(data))
      .then((res) => {
        setIsLoading(false);
        if (res.payload.statusCode === 200) {
          //message.success(res.payload.message);
          if (!prop.done) {
            //prop.setCurrent((prev) => prev + 1);
            setUpdate((prev) => !prev);
            if (changedFields[0].value == false) {
              prop.setSteps1(abc);
            } else {
              prop.setSteps1(prop.steps);
            }
          } else {
            navigate("/client");
          }
        } else {
          message.error(res.payload.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <Form
      ref={prop.refs}
      form={form}
      name="basic12"
      onFinish={onFinish}
      autoComplete="off"
      onFieldsChange={handleChange}
    >
      <div className="service-section job-section">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                Service Trial Job Required
              </label>
              <Form.Item
                name="isTrialJob"
                rules={[
                  {
                    required: true,
                    message: "Please Select Option!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default Job;
