import React from "react";
import { Tabs } from "antd";
import UG from "./UG";
import TClient from "./client";
import "./menber.scss";
import { useState } from "react";

const { TabPane } = Tabs;

const Tabbbings = (prop) => {
  const [key1, setKey1] = useState("1");

  const handleChange = (value) => {
    console.log(value)
    setKey1(value)
  }
  return (
    <>
      <Tabs activeKey={key1} onChange={handleChange}>
        <TabPane tab={<span>Client Team</span>} key="1">
          <TClient
            refs={prop.refs}
            setKey1={setKey1}
            setCurrent={prop.setCurrent}
          />
        </TabPane>
        <TabPane tab={<span>UG Team</span>} key="2">
          <UG refs={prop.refs} setCurrent={prop.setCurrent} />
        </TabPane>
      </Tabs>
    </>
  );
};

export default Tabbbings;
