import React from 'react';


export const Email = () => (
	<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M2.10938 -1.91374e-06L21.8906 -1.84407e-07C23.0561 -8.25203e-08 24 0.949781 24 2.10938L24 14.7656C24 15.9321 23.0491 16.875 21.8906 16.875L2.10937 16.875C0.953671 16.875 -1.39318e-06 15.9361 -1.29085e-06 14.7656L-1.84407e-07 2.10937C-8.32128e-08 0.951842 0.940172 -2.01595e-06 2.10938 -1.91374e-06ZM2.40478 1.40625C2.83575 1.83492 10.2524 9.21248 10.5084 9.4672C10.9069 9.86564 11.4366 10.085 12 10.085C12.5634 10.085 13.0931 9.86559 13.4929 9.46589C13.6651 9.29456 20.9999 1.99847 21.5952 1.40625L2.40478 1.40625ZM22.5937 14.4794L22.5937 2.39648L16.517 8.44125L22.5937 14.4794ZM21.5943 15.4687L15.5199 9.43298L14.4859 10.4616C13.8219 11.1256 12.939 11.4913 12 11.4913C11.061 11.4913 10.1781 11.1256 9.51539 10.4629L8.48006 9.43298L2.40567 15.4687L21.5943 15.4687ZM1.40625 14.4794L7.48303 8.44125L1.40625 2.39648L1.40625 14.4794Z" fill="#1188F3" />
	</svg>
)

export const Password = () => (
	<svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M9.00001 0.705872C5.4706 0.705872 2.64707 3.5294 2.64707 7.05881V9.88234C1.44707 9.88234 0.529419 10.8 0.529419 12V21.1765C0.529419 22.3765 1.44707 23.2941 2.64707 23.2941H15.3529C16.5529 23.2941 17.4706 22.3765 17.4706 21.1765V12C17.4706 10.8 16.5529 9.88234 15.3529 9.88234V7.05881C15.3529 3.5294 12.5294 0.705872 9.00001 0.705872ZM16.0588 12V21.1765C16.0588 21.6 15.7765 21.8823 15.3529 21.8823H2.64707C2.22354 21.8823 1.94118 21.6 1.94118 21.1765V12C1.94118 11.5765 2.22354 11.2941 2.64707 11.2941H3.35295H14.6471H15.3529C15.7765 11.2941 16.0588 11.5765 16.0588 12ZM4.05883 9.88234V7.05881C4.05883 4.30587 6.24707 2.11764 9.00001 2.11764C11.7529 2.11764 13.9412 4.30587 13.9412 7.05881V9.88234H4.05883Z" fill="#1188F3" />
		<path d="M9.00003 13.4117C7.80003 13.4117 6.88239 14.3294 6.88239 15.5294C6.88239 16.447 7.44709 17.2235 8.29415 17.5059V19.0588C8.29415 19.4823 8.5765 19.7647 9.00003 19.7647C9.42356 19.7647 9.70591 19.4823 9.70591 19.0588V17.5059C10.553 17.2235 11.1177 16.447 11.1177 15.5294C11.1177 14.3294 10.2 13.4117 9.00003 13.4117ZM9.00003 16.2353C8.5765 16.2353 8.29415 15.9529 8.29415 15.5294C8.29415 15.1059 8.5765 14.8235 9.00003 14.8235C9.42356 14.8235 9.70591 15.1059 9.70591 15.5294C9.70591 15.9529 9.42356 16.2353 9.00003 16.2353Z" fill="#1188F3" />
	</svg>
)
export const User = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_44_46582)">
			<path d="M11.829 11.5609C13.4172 11.5609 14.7925 10.9912 15.9163 9.86733C17.04 8.74362 17.6096 7.36868 17.6096 5.78025C17.6096 4.19237 17.04 2.81725 15.9161 1.69317C14.7922 0.569639 13.4171 0 11.829 0C10.2405 0 8.8656 0.569639 7.74188 1.69335C6.61816 2.81707 6.04834 4.19218 6.04834 5.78025C6.04834 7.36868 6.61816 8.7438 7.74207 9.86751C8.86597 10.991 10.2411 11.5609 11.829 11.5609Z" fill="white" />
			<path d="M21.9435 18.4547C21.9111 17.9871 21.8456 17.477 21.7491 16.9383C21.6517 16.3956 21.5262 15.8825 21.3761 15.4136C21.221 14.9289 21.0101 14.4502 20.7493 13.9916C20.4787 13.5155 20.1608 13.1009 19.8041 12.7598C19.4312 12.403 18.9745 12.116 18.4464 11.9067C17.9202 11.6985 17.337 11.5931 16.7131 11.5931C16.4681 11.5931 16.2312 11.6936 15.7736 11.9915C15.492 12.1752 15.1626 12.3876 14.7949 12.6225C14.4805 12.8228 14.0546 13.0105 13.5286 13.1804C13.0153 13.3465 12.4942 13.4307 11.9799 13.4307C11.4655 13.4307 10.9446 13.3465 10.4308 13.1804C9.90527 13.0107 9.47937 12.823 9.16534 12.6227C8.80115 12.39 8.47156 12.1775 8.18573 11.9913C7.7287 11.6934 7.49158 11.5929 7.24658 11.5929C6.62256 11.5929 6.03955 11.6985 5.51349 11.9069C4.98578 12.1158 4.52893 12.4028 4.15558 12.76C3.79907 13.1013 3.48102 13.5157 3.21075 13.9916C2.9502 14.4502 2.73926 14.9287 2.58398 15.4137C2.43402 15.8827 2.30859 16.3956 2.21118 16.9383C2.11469 17.4762 2.04913 17.9865 2.01672 18.4553C1.98486 18.9145 1.96875 19.3911 1.96875 19.8725C1.96875 21.1253 2.367 22.1395 3.15234 22.8875C3.92798 23.6256 4.95428 24.0001 6.20233 24.0001H17.7585C19.0065 24.0001 20.0325 23.6258 20.8083 22.8875C21.5938 22.1401 21.9921 21.1257 21.9921 19.8723C21.9919 19.3888 21.9756 18.9118 21.9435 18.4547Z" fill="white" />
		</g>
		<defs>
			<clipPath id="clip0_44_46582">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
)




