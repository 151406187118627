import React, { useEffect, useState } from "react";
import "./user.scss";
import { Layout, Input, Button, Table, Row, message, Switch } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/common/Breadcrumb";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import MDelete from "../../../components/common/Delete";
import MExcel from "../../../components/common/excel";
import { useDispatch } from "react-redux/es/exports";
import { getUsers } from "../../../Redux/userSlice";

const ListUser = (prop) => {
  const dispatch = useDispatch();
                                    
  const [users, setUsers] = useState();
  const [totalUsers, setTotalUsers] = useState();
  const [columns, setColumns] = useState();
                                                                                                        
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [id, setId] = useState();
  const [content1, setContent1] = useState();
  const [content2, setContent2] = useState();
  const [status, setStatus] = useState();

  const [column, setColumn] = useState("");
  const [order, setOrder] = useState("");

  const [pageName, setpageName] = useState();
  const [userType, setuserType] = useState();
  const [link, setLink] = useState("");

  useEffect(() => {
    setpageName(prop.pageName);
    setuserType(prop.userType);
    setLink(prop.link);
    setSearch("");
  }, [prop]);

  useEffect(() => {
    const data = { limit, skip, search, userType, column, order };

    if (userType) {
      setIsLoading(true);
      dispatch(getUsers(data))
        .then((res) => {
          //console.log(res.payload.data);
          setIsLoading(false);
          if (res.payload.statusCode === 200) {
            setUsers(
              res.payload.data.list.map((data, index) => {
                return { ...data, key: index };
              })
            );
            setTotalUsers(res.payload.data.total_records);
          } else {
            message.error(res.payload.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });                    
    }
  }, [limit, skip, search, column, order, userType]);
                    
  //Modal
  const [ModalVisible, setModalVisible] = useState(false);
  const showModal = (id, status) => {
    if (status === "delete") {
      setContent1("Are you sure you want to delete user ?");
      setContent2("Your action can't be undone");
      setStatus(status);
    } else {
      setContent1(`Do you want to ${status ? "Active" : "Inactive"} User ?`);
      setContent2(" ");
      setStatus(status);
    }
    setId(id);
    setModalVisible(true);
  };

  const [excelInfoVisible, setexcelInfoVisible] = useState(false);
  const showExcle = () => {
    setexcelInfoVisible(true);
  };

  const handleSort = (value) => {
    setColumn(value);
    setOrder((prev) => {
      if (prev === "asc") return "desc";
      else return "asc";
    });
  };

  useEffect(() => {
    //console.log(userType);
    if (userType) {
      let columns1 = [
        {
          title: (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleSort("fullName")}
            >
              Full Name
            </div>
          ),
          dataIndex: "fname",
          key: "fname",
          render: (data, record) => (
            <div>
              <div>{record.firstName + " " + record.lastName}</div>
            </div>
          ),
        },
        {
          title: (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleSort("email")}
            >
              Email
            </div>
          ),
          dataIndex: "email",
          key: "email",
          render: (data, record) => (
            <div>
              <div>{record.email}</div>
            </div>
          ),
        },
        {
          title: (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleSort("phoneNumber")}
            >
              Phone Number
            </div>
          ),
          dataIndex: "phoneNumber",
          key: "pnumber",
        },
        {
          title: "Account Status",
          dataIndex: "isActive",
          key: "Status",
          render: (row, record) => (
            <div>
              <Switch
                checked={row ? true : false}
                onChange={() => showModal(record._id, !row)}
              />
            </div>
          ),
        },                                                            
        {    
          title: "Action",
          dataIndex: "action",
          key: "action",                                                                                    
          className: "view",
          render: (row, record) => (
            <ul className="action-col">
              <li className="/user/edit_user">
                <Link
                  to={"/" + link + "/edit"}
                  state={{ id: record._id }}
                  className="common-btn"
                >
                  <EditTwoTone />
                </Link>
              </li>
              <li>
                <DeleteTwoTone
                  onClick={() => showModal(record._id, "delete")}
                />
              </li>
            </ul>
          ),
        },
      ];                                                                                                        

      if (userType === 7) {
        //console.log(columns1.slice(4));
        columns1 = [
          ...columns1.slice(0, 3),
          {
            title: (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleSort("designation")}
              >
                Designation
              </div>
            ),
            dataIndex: "designation",
            key: "designation",
            render: (data, record) => {
              if (data === 1) {
                return (
                  <div>
                    <div>Accountant </div>
                  </div>
                );
              } else if (data === 2) {
                return (
                  <div>
                    <div>Member</div>
                  </div>
                );
              } else {
                return (
                  <div>
                    <div> Project Manager</div>
                  </div>
                );
              }
            },
          },
          ...columns1.slice(3, columns1.length),
        ];
        //console.log(columns1);
      }
      setColumns(columns1);
    }
  }, [userType]);

  return (
    <Layout className="site-layout-background">
      <Content className="admin-webpanel-content">
        {/* <Breadcrumbs content={"List of " + pageName} /> */}

        <div className="filter-wrap  event-wrap">
          <h2 className="title">{pageName} </h2>
          <Row justify="end" className="btn-box">
            {userType !== 6 ? (
              <Button
                type="primary"
                htmlType="submit"
                className="btn-primary"
                onClick={showExcle}
              >
                Upload Excel To Add
              </Button>
            ) : null}
            <Button type="primary" htmlType="submit" className="btn-primary">
              <Link to={"/" + link + "/add"}>{"Add New " + pageName}</Link>
            </Button>
          </Row>
        </div>

        <div className="remove-wrap">
          <div className="event-wrap">
            <Row className="search" justify="end">
              <div className="col-lg-3 col-sm-4">
                <Input
                  placeholder="Search here"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </Row>

            <div className="common-table">
              <Table
                bordered
                columns={columns}
                Loading={isLoading}
                dataSource={users}
                scroll={{ x: 870 }}
                pagination={{
                  current: page,
                  defaultPageSize: limit,
                  total: totalUsers,
                  showTotal: (total) => `Total ${total} records`,
                  onChange: (page, pagesize) => {
                    setPage(page);
                    setSkip((page - 1) * pagesize);
                  },
                }}
              />
            </div>
          </div>
        </div>

        {/* modale */}
        {ModalVisible ? (
          <MDelete
            setModalVisible={setModalVisible}
            ModalVisible={ModalVisible}
            content1={content1}
            content2={content2}
            status={status}
            id={id}
            data={users}
            setData={setUsers}
            setTotalData={setTotalUsers}
            api="user/deleteUser"
          />
        ) : null}

        {excelInfoVisible ? (
          <MExcel
            setexcelInfoVisible={setexcelInfoVisible}
            excelInfoVisible={excelInfoVisible}
          />
        ) : null}
      </Content>
    </Layout>
  );
};

export default ListUser;
