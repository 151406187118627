import { FilePdfOutlined } from "@ant-design/icons";
import { DatePicker, Form, Upload } from "antd";
import axios from "axios";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { API_BASE } from "../../../constants/api";

const Invoice = (prop) => {
  const [form] = Form.useForm();
  const baseURL = "https://clienton-boarding.s3.amazonaws.com/invoice/"

  const [invoiceFile, setInvoiceFile] = useState();
  const [invoiceData, setInvoiceData] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    axios
      .post(`${API_BASE}v1/clientForm/getInvoiceDetails/`, {clientID :clientid})
      .then((res) => {
        setInvoiceData(res.data.data);
      })
      .catch((err) => {     
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (invoiceData) {
      form.setFieldsValue({
        invoiceGeneratedDate: moment(invoiceData.invoiceGeneratedDate),
        invoiceSentDate: moment(invoiceData.invoiceSentDate),
        paymentReceivedDate: moment(invoiceData.paymentReceivedDate),
      });
      setInvoiceFile(invoiceData.invoicePDF);
    }
  }, [invoiceData]);

  const onFinish = () => {
    prop.setCurrent((prev) => prev + 1);
  };

  const props = {
    maxCount: 1,
    accept: ".pdf",
    beforeUpload: () => {
      return false;
    },
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: "Download",
      showRemoveIcon: false,
    },
  };

  return (
    <Form
      ref={prop.refs}
      form={form}
      name="basic12"
      onFinish={onFinish}
      autoComplete="off"
    >
      {/* <div className="service-section job-section">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                Invoice Generation Date
              </label>
              <Form.Item
                name="invoiceGeneratedDate"
                rules={[
                  {
                    required: true,
                    message: "Please Select Option!",
                  },
                ]}
              >
                <DatePicker disabled />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>*/}
      <div className="service-section job-section">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                {/* <span className="required">*</span> */}
                Invoice Sent Date
              </label>
              <Form.Item
                name="invoiceSentDate"
              >
                <DatePicker disabled />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="service-section job-section">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                Payment Received Date
              </label>
              <Form.Item
                name="paymentReceivedDate"
                rules={[
                  {
                    required: true,
                    message: "Please Select Option!",
                  },
                ]}
              >
                <DatePicker disabled />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>  */}

      <div className="service-section job-section">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">Your Invoice PDF:</label>
              <Form.Item name="invoice" className="upload-pdf">
              {invoiceFile ? (
                <Upload
                  {...props}
                  fileList={
                    invoiceFile
                      ? [
                          {
                            uid: "1",
                            name: "Invoice",
                            url: baseURL + invoiceFile,                          
                          },
                        ]
                      : []
                  }
                ><FilePdfOutlined /></Upload>                
              ) : (
                <p>---</p>
              )}
              </Form.Item>
            </div>
            <div style={{ color: "red" }}>{error}</div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default Invoice;
