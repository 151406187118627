import { Form, message, Row, Select, Table } from "antd";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createGlobalStyle } from "styled-components";
import { API_BASE } from "../../../constants/api";
import {
  fillClientForm,
  getClientDetail,
} from "../../../Redux/clientFormSlice";
import { getUsers } from "../../../Redux/userSlice";

const UG = (prop) => {
  const [team, setTeam] = useState(false);
  const [form] = Form.useForm();

  const [manager, setManager] = useState();
  const [error, setError] = useState();
  const [teamMember, setTeamMember] = useState();
  const [dataSource, setDataSource] = useState([]);

  const [teamMemberIds, setTeamMemberIds] = useState([]);
  const [managerIds, setManagerIds] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  
  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    const data = {
      clientID: clientid,
      teamMemberType: 1,
    };

    setIsLoading(true);
    axios
      .post(`${API_BASE}v1/team-member/getAssignTeamMemberList`, data)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          console.log(res?.data?.data?.list?.[0]?.teamMemberList);
          setDataSource(
            res?.data?.data?.list?.[0]?.teamMemberList?.map((data) => {
              if (data.userType === 7) {
                return {
                  fullName: data.clientName,
                  email: data.email,
                  _id: data._id,
                  key: data._id,
                  role: "Team Member",
                };
              } else {
                return {
                  fullName: data.clientName,
                  email: data.email,
                  _id: data._id,
                  key: data._id,
                  role: "Manager",
                };
              }
            })
          );
          let tids = res?.data?.data?.list?.[0]?.teamMemberList?.filter(
            (data) => data.userType === 7
          );
          let mids = res?.data?.data?.list?.[0]?.teamMemberList?.filter(
            (data) => data.userType === 5
          );
          //console.log(mids.map((data) => data._id));
          setManagerIds(mids.map((data) => data._id));
          setTeamMemberIds(tids.map((data) => data._id));
          setTeam(true);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    if (clientid) {
      setIsLoading(true);
      dispatch(getClientDetail(clientid))
        .then((res) => {
          setIsLoading(false);
          if (res.payload.statusCode === 200) {
            //console.log(res.payload.data);
            //setJobData(res.payload.data);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, []);

  //getting Team Member
  useEffect(() => {
    const data = { limit: 10000, userType: 7 };
    setIsLoading(true);
    dispatch(getUsers(data))
      .then((res) => {
        //console.log(res.payload.data);
        setIsLoading(false);
        if (res.payload.statusCode === 200) {
          setTeamMember(
            res.payload.data.list.map((data, index) => {
              return { ...data, key: index };
            })
          );
          //setTotalUsers(res.payload.data.total_records);
        } else {
          message.error(res.payload.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const data = { limit: 10000, userType: 5 };
    setIsLoading(true);
    dispatch(getUsers(data))
      .then((res) => {
        //console.log(res.payload.data);
        setIsLoading(false);
        if (res.payload.statusCode === 200) {
          setManager(
            res.payload.data.list.map((data, index) => {
              return { ...data, key: index };
            })
          );
        } else {
          message.error(res.payload.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  //console.log(dataSource);
  const columns = [
    {
      title: "Person Name",
      dataIndex: "fullName",
      key: "person",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "phone",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "Role",
    },
  ];

  const onFinish = (values) => {
    // if (dataSource.length === 0) {
    //   setError("Please Select Team");
    //   return;
    // }
    // setError("");
    // let userID = localStorage.getItem("userID");
    // const data = {
    //   userID,
    //   mainStepNo: 8,
    //   subStepNo: 1,
    //   selectedTeamMember: { UGTeamIDS: dataSource.map((data) => data._id) },
    // };

    prop.setCurrent((prev) => prev + 1);

    // setIsLoading(true);
    // dispatch(fillClientForm(data))
    //   .then((res) => {
    //     setIsLoading(false);
    //     if (res.payload.statusCode === 200) {
    //       message.success(res.payload.message);
    //       prop.setCurrent((prev) => prev + 1);
    //       //prop.setKey1("2");
    //     } else {
    //       message.error(res.payload.message);
    //     }
    //   })
    //   .catch((err) => {
    //     setIsLoading(false);
    //     console.log(err);
    //   });
  };

  const handleTeamMember = (ids, option) => {
    //selectedTeamMembers(option);
    //console.log(ids);
    let tDataSource;
    tDataSource = dataSource.filter((data) => data.role === "Manager");
    //console.log(tDataSource);

    let abc = [];
    ids.map((data) => {
      let tarray = teamMember.filter((data1) => {
        if (data1._id === data) {
          return true;
        } else {
          return false;
        }
      });
      abc = [...abc, ...tarray];
      //console.log(abc);
    });

    setDataSource([
      ...tDataSource,
      ...abc.map((data) => {
        return { ...data, role: "Team Member", key: data._id };
      }),
    ]);
  };

  const handleManager = (ids, option) => {
    let tdataSource = dataSource.filter((data) => {
      if (data?.role === "Manager") {
        return false;
      } else {
        return true;
      }
    });
    //console.log(tdataSource);
    let tmanager = manager.find((data) => data._id === ids);
    //console.log(tmanager);
    if (tmanager) {
      tmanager = { ...tmanager, key: tmanager._id };
      setDataSource(tdataSource.concat({ ...tmanager, role: "Manager" }));
    } else {
      setDataSource(tdataSource);
    }
  };

  return (
    <>
      <Form
        ref={prop.refs}
        form={form}
        name="basic12"
        onFinish={onFinish}
        autoComplete="off"
      >
        {/* <Row className="btn-box ug">
          <div
            type="primary"
            htmlType="submit"
            className="btn-secondary"
            onClick={() => setTeam((prev) => !prev)}
          >
            Manage Team Member
          </div>
        </Row> */}

        {/* {team ? (
          <>
            <div>
              <Form.Item
                name="teamMember"
                label="Team Members"
                rules={[
                  {
                    required: true,
                    message: "Please Select!",
                  },
                ]}
                initialValue={teamMemberIds ? teamMemberIds : []}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Please select"
                  onChange={handleTeamMember}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {teamMember &&
                    teamMember?.map((data) => (
                      <Select.Option key={data._id} value={data._id}>
                        {data.fullName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              
            </div>

            <Row className="btn-box ug">
              <Form.Item
                name="manager"
                label="Manager"
                rules={[
                  {
                    required: true,
                    message: "Please Select!",
                  },
                ]}
                initialValue={managerIds ? managerIds : []}
              >
                <Select
                  onChange={handleManager}
                  allowClear
                  placeholder="Please select"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {manager &&
                    manager?.map((data) => (
                      <Select.Option key={data._id} value={data._id}>
                        {data.fullName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Row>
          </>
        ) : null} */}

        <div className="common-table">
          <Table
            bordered
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: 800 }}
          />
        </div>
        <div style={{ color: "red" }}>{error ? "*" + error : null}</div>
      </Form>
    </>
  );
};

export default UG;
