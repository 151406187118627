import React from "react";
import Header from "./header";
// import Footer from "./footer";
// import Sidebar from "./sidebar";
import Layout from "antd/lib/layout/layout";
import "./layout.scss";

const WithLayout = ({ children }) => {
  return (
    <>
      <Header />
      <Layout className="main-wrap ant-layout-has-sider">
        {/* <Sidebar /> */}
        {children}
        {/* <Footer /> */}
      </Layout>
    </>
  );
};

export default WithLayout;
