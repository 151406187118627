import {
  Button,
  DatePicker,
  InputNumber,
  Row,
  Table,
  message,
  Select,
  Upload,
  Form,
  Col,
  Modal,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import AddNote from "../../../components/common/AddNote";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  fillClientForm,
  getClientDetail,
} from "../../../Redux/clientFormSlice";
import axios from "axios";
import { API_BASE } from "../../../constants/api";

const dateFormat = "MMM YYYY";
const date = moment(new Date()).format(dateFormat);

function Trial(prop) {
  const baseURL = "https://clienton-boarding.s3.amazonaws.com/trial-service/"

  const [form] = Form.useForm();
  const [id, setId] = useState();
  const [trialData, setTrialData] = useState();
  const [trialList, setTrialList] = useState();

  const [jobDoneBy, setJobDoneBy] = useState();
  const [error, setError] = useState();
  const [error1, setError1] = useState();

  const [status, setStatus] = useState();
  const [excelInfoVisible, setexcelInfoVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const [timeSheet, setTimeSheet] = useState();
  const [updated1, setUpdated1] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState();
  const [totalNotes, setTotalNotes] = useState();
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  const note = () => {
    setexcelInfoVisible(true);
  };

  const props = {
    name: "fileList",
    maxCount: 1,
    accept:
      ".pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    beforeUpload: () => {
      return false;
    },
    onChange(info) {
      //console.log(info);
      if (info.fileList.length) {
        let formData = new FormData();
        formData.append("image", info.file);
        formData.append("moduleName", "trial-service");


        setIsLoading(true);
        axios
          .post(`${API_BASE}common/S3upload`, formData)
          .then((res) => {
            setIsLoading(false);
            //console.log(res.data.data.name);
            setDataSource(
              dataSource.map((data) => {
                if (data.key === id) {
                  return {
                    ...data,
                    document: res.data.data.name,
                    updated: true,
                  };
                } else {
                  return data;
                }
              })
            );
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
      }
    },
    onRemove(file) {
      //console.log(file);
      let id1;
      if (file?.url) {
        id1 = file.uid;
      } else {
        id1 = id;
      }
      setDataSource(
        dataSource.map((data) => {
          if (data.key === id1) {
            return { ...data, document: "" };
          } else {
            return data;
          }
        })
      );
    },
  };

  const columns = [
    {
      title: "Job Description",
      dataIndex: "job",
      key: "job",
      render: (data) => <div>{data}</div>,
    },
    {
      title: "Period of trial job (Month & Year)",
      dataIndex: "month",
      key: "month",
      render: (data, record) => {
        if (record.job !== "Total") {
          return (
            <>
              <Form.Item
                name={"month" + record.key}
                rules={[
                  {
                    required: record.time1 > 0 ? true : false,
                    message: "Please Select Date!",
                  },
                ]}
                initialValue={data ? data : null}
              >
                <DatePicker
                  format={dateFormat}
                  picker="month"
                  inputReadOnly="true"
                  onChange={(e1, e2) => handleUpdate(e1, record.key, "month")}
                />
              </Form.Item>
            </>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      title: "Expected turnaround time",
      dataIndex: "time1",
      key: "time",
      render: (data, record) => {
        if (record.job !== "Total") {
          return (
            <Form.Item
              name={"time1" + record.key}
              style={{ maxWidth: "120px" }}
              rules={[
                {
                  required: true,
                  message: "Please Add hours!",
                },
                () => ({
                  validator(_, value) {
                    if (value && value < 0) {
                      return Promise.reject("Provide valid hours");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              initialValue={data}
            >
              <InputNumber
                className="addon"
                addonBefore="hr"
                value={data}
                onChange={(e) => handleUpdate(e, record.key, "time1")}
              />
            </Form.Item>
          );
        } else {
          return <div>{data}</div>;
        }
      },
    },
    {
      title: "Upload a document for job",
      dataIndex: "document",
      key: "document",
      render: (data, record) => {
        if (record.job !== "Total") {
          return (
            <>
              <Form.Item name="abc" style={{ maxWidth: "120px" }}>
                <Upload
                  {...props}
                  fileList={
                    data
                      ? [
                          {
                            uid: record.key,
                            name: record?.updated
                              ? "New Document"
                              : "Old Document",
                            status: "done",
                            url: baseURL + data,
                          },
                        ]
                      : []
                  }
                >
                  <Button
                    icon={<UploadOutlined />}
                    onClick={() => setId(record.key)}
                  >
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
              {/* {!data && !record.newDoc ? (
                <span style={{ color: "red" }}>Please select File</span>
              ) : (
                <></>
              )} */}
            </>
          );
        }
      },
    },
    // {
    //   title: "UG turnaround time",
    //   dataIndex: "time2",
    //   key: "time2",
    //   render: (data, record) => {
    //     if (record.job !== "Total") {
    //       return (
    //         <Form.Item
    //           name={"time2" + record.key}
    //           style={{ maxWidth: "120px" }}
    //           rules={[
    //             {
    //               required: true,
    //               message: "Please Select time!",
    //             },
    //           ]}
    //           initialValue={data}
    //         >
    //           <InputNumber
    //             className="addon"
    //             addonBefore="hr"
    //             onChange={(e) => handleUpdate(e, record.key, "time2")}
    //           />
    //         </Form.Item>
    //       );
    //     } else {
    //       return <div>{data}</div>;
    //     }
    //   },
    // },
    // {
    //   title: "Job Done By",
    //   dataIndex: "doneBy",
    //   key: "doneBy",
    //   render: (data, record) => {
    //     if (record.job !== "Total") {
    //       return (
    //         <Form.Item
    //           name={"doneBy" + record.key}
    //           dependencies={["reviewedBy" + record.key]}
    //           style={{ minWidth: "150px" }}
    //           rules={[
    //             {
    //               required: record.time2 > 0 ? true : false,
    //               message: "Please Select User!",
    //             },
    //           ]}
    //           initialValue={data}
    //         >
    //           <Select
    //             placeholder="Select User"
    //             onChange={(e) => handleUpdate(e, record.key, "doneBy")}
    //           >
    //             {jobDoneBy?.map((data) => (
    //               <Select.Option key={data._id} value={data._id}>
    //                 {data.clientName}
    //               </Select.Option>
    //             ))}
    //           </Select>
    //         </Form.Item>
    //       );
    //     }
    //   },
    // },
    // {
    //   title: "Reviewed By",
    //   dataIndex: "reviewedBy",
    //   // columnWidth: 150,
    //   key: "reviewedBy",
    //   render: (data, record) => {
    //     if (record.job !== "Total") {
    //       return (
    //         <Form.Item
    //           name={"reviewedBy" + record.key}
    //           dependencies={["doneBy" + record.key]}
    //           style={{ minWidth: "150px" }}
    //           rules={[
    //             {
    //               required: record.time2 > 0 ? true : false,
    //               message: "Please Select User!",
    //             },
    //             () => ({
    //               validator(_, value) {
    //                 if (value && value === record.doneBy) {
    //                   return Promise.reject(
    //                     "JobDoneBy and JobReviewBy person can't be same"
    //                   );
    //                 }
    //                 return Promise.resolve();
    //               },
    //             }),
    //           ]}
    //           initialValue={data}
    //         >
    //           <Select
    //             placeholder="Select User"
    //             onChange={(e) => handleUpdate(e, record.key, "reviewedBy")}
    //           >
    //             {jobDoneBy?.map((data) => (
    //               <Select.Option key={data._id} value={data._id}>
    //                 {data.clientName}
    //               </Select.Option>
    //             ))}
    //           </Select>
    //         </Form.Item>
    //       );
    //     }
    //   },
    // },
  ];

  //console.log("dataSource", dataSource);
  const handleUpdate = (e, key, field) => {
    //console.log(e, key, field);

    let tdata = dataSource.map((data) => {
      if (data.key === key) {
        return { ...data, [field]: e };
      } else {
        return data;
      }
    });

    let total = 0;
    if (field === "time1") {
      tdata.map((data) => {
        if (data.job !== "Total") {
          total = total + data.time1;
        }
      });
      //console.log(total);
      tdata = tdata.map((data) => {
        if (data.job === "Total") {
          return { ...data, time1: total };
        } else {
          return data;
        }
      });
    } else if (field === "time2") {
      tdata.map((data) => {
        if (data.job !== "Total") {
          total = total + data.time2;
        }
      });

      tdata = tdata.map((data) => {
        if (data.job === "Total") {
          return { ...data, time2: total };
        } else {
          return data;
        }
      });
    }
    setDataSource(tdata);
  };

  //getting Trial List
  useEffect(() => {
    //console.log("Trial List");
    let clientid = localStorage.getItem("clientID");
    const data = {
      clientID: clientid,
    };
    setIsLoading(true);
    axios
      .post(`${API_BASE}v1/clientForm/listTrailServiceRequest`, data)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          setTrialList(
            res?.data?.data?.[0]?.list?.[0]?.trialRequestServiceDetail
              ? res?.data?.data?.[0]?.list?.[0]?.trialRequestServiceDetail
              : []
          );
          let tsum1 = 0,
            tsum2 = 0;
          let tdataSource =
            res?.data?.data?.[0]?.list?.[0]?.trialRequestServiceDetail?.map(
              (data, index) => {
                console.log(data);
                tsum1 = tsum1 + parseInt(data.expectedTurnAroundTime);
                tsum2 = tsum2 + parseInt(data.UGTurnAroundTime);
                return {
                  key: data.subServiceID,
                  //key: data._id,
                  job: data.subServiceName,
                  doneBy: data?.jobDoneBy || "",
                  reviewedBy: data?.jobReviewBy || "",
                  time1: parseInt(data.expectedTurnAroundTime),
                  time2: parseInt(data.UGTurnAroundTime),
                  month: moment(data.trialJobDate),
                  document: data.document,
                  updated: false,
                };
              }
            );
          if (res?.data?.data?.[0]?.list.length !== 0) {
            setDataSource(
              tdataSource.concat({
                key: 0,
                job: "Total",
                time1: tsum1,
                time2: tsum2,
              })
            );
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  //getting Client Data
  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    setIsLoading(true);
    dispatch(getClientDetail(clientid))
      .then((res) => {
        setIsLoading(false);
        if (res.payload.statusCode === 200) {
          //console.log(res.payload.data);
          setTrialData(res.payload.data);
          setStatus(res?.payload?.data?.trialJobStatus || "");
          setTimeSheet(res?.payload?.data?.timesheetDoc || "");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [trialList]);

  //getting title of subservice type
  useEffect(() => {
    const data = { limit: 10000 };
    if (trialData && dataSource?.length === 0 && trialList?.length === 0) {
      //console.log(trialList);
      setIsLoading(true);
      axios
        .post(`${API_BASE}v1/sub-service-type/getSubServiceTypeList`, data)
        .then((res) => {
          setIsLoading(false);
          if (res.data.statusCode === 200) {
            console.log(res.data.data.list);
            //setTrialData(res.payload.data.list);
            let tarray = [];
            trialData.selectedService.map((data) => {
              tarray = [
                ...tarray,
                ...data.selectedServiceTypeIDs.map((data1) => {
                  return data1.selectedSubServiceIDs;
                }),
              ];
            });
            //console.log(tarray);

            let tarray1 = [];
            tarray.map((data) => {
              tarray1 = [...tarray1, ...data];
            });

            let tarray2 = [];
            res.data.data.list.map((data) => {
              let abc = false;
              tarray1.map((data1) => {
                if (data._id === data1) {
                  abc = true;
                }
              });
              if (abc) {
                tarray2.push(data);
              }
            });

            tarray2 = tarray2.map((data) => {
              return {
                key: data._id,
                job: data.title,
                doneBy: "",
                reviewedBy: "",
                time1: 0,
                time2: 0,
                month: "",
                document: "",
                newDoc: true,
              };
            });

            tarray2.push({
              key: 0,
              job: "Total",
              time1: 0,
              time2: 0,
            });
            //console.log(tarray2);
            setDataSource(tarray2);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, [trialData]);

  //users for jobDone and jobreview
  useEffect(() => {
    let data = { userType: [5, 7] };
    setIsLoading(true);
    axios
      .post(`${API_BASE}v1/user/getListOfUserName`, data)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          //console.log(res.data.data.list);
          setJobDoneBy(res.data.data.list);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  const onFinish = (values) => {
    let tobject = dataSource.find((data) => data.job === "Total");

    if (tobject.time1 === 0) {
      setError("Select atlest one Trial Service");
      return;
    }

    let tdata = dataSource.find((data) => data.job === "Total");
    if (tdata.time1 > 15 || tdata.time2 > 15) {
      setError(
        "Expected turnaround time total should be less than be 15 hours"
      );
      return;
    }

    //console.log(dataSource);

    let doc_error = false;
    dataSource.map((data) => {
      if (data?.document && !data.document) {
        doc_error = true;
        setError("Please Select File");
      }
    });
    if (doc_error) {
      return;
    }
    setError("");

    let clientid = localStorage.getItem("clientID");
    let ttdata = dataSource.map((data) => {
      return {
        //clientID: clientid,
        subServiceID: data.key,
        trialJobDate: data?.month
          ?.subtract(data?.month?.format("DD") - 1, "days")
          ?.utc()
          ?.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        expectedTurnAroundTime: data.time1,
        document: data.document,
        // UGTurnAroundTime: data.time2,
        // jobDoneBy: data.doneBy,
        // jobReviewBy: data.reviewedBy,
      };
    });
    //console.log(ttdata);

    //removing empty jobdoneby and jobReviewd By
    ttdata.map((data) => {
      //console.log(data);
      if (data.jobDoneBy === "") {
        delete data.jobDoneBy;
      }
      if (data.jobReviewBy === "") {
        delete data.jobReviewBy;
      }
      return data;
    });

    //Removing Total Row
    const data = {
      clientID: clientid,
      trialRequestService: ttdata.filter((data) => {
        if (data.subServiceID === 0) {
          return false;
        } else {
          return true;
        }
      }),
    };
    //console.log(data);

    let error = false;
    setIsLoading(true);
    axios
      .post(`${API_BASE}v1/clientForm/addTrailServiceRequest`, data)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          setError1(false);
        } else {
          message.error(res.data.message);
          setError1(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError1(true);
        message.error(err.response.data.message);
      });
  };

  useEffect(() => {
    if (error1 === false) {
      if (!error1) {
        let userID = localStorage.getItem("userID");
        const newData = {
          trialJobStatus: status,
          mainStepNo: 5,
          subStepNo: 0,
          userID,
        };
        setIsLoading(true);
        dispatch(fillClientForm(newData))
          .then((res) => {
            setIsLoading(false);
            if (res.payload.statusCode === 200) {
              message.success(res.payload.message);
              prop.setCurrent((prev) => prev + 1);
            } else {
              message.error(res.payload.message);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
      }
    }
  }, [error1]);

  const columns1 = [
    {
      title: "Change Request Notes",
      dataIndex: "notes",
      key: "Notes",
    },
  ];

  //getting notes
  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    const data = { limit: 10000, skip, clientID: clientid, notesType: 3 };

    setIsLoading(true);
    axios
      .post(`${API_BASE}v1/clientForm/getClientNotesList`, data)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          setNotes(
            res.data.data.list.map((data) => {
              return { ...data, key: data._id };
            })
          );
          setTotalNotes(res.data.data.list.length);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [skip, excelInfoVisible]);

  //Timesheet
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    let userID = localStorage.getItem("userID");
    const newData = {
      trialJobStatus: status,
      mainStepNo: 5,
      subStepNo: 0,
      userID,
      timesheetDoc: timeSheet,
    };
    setIsLoading(true);
    dispatch(fillClientForm(newData))
      .then((res) => {
        setIsLoading(false);
        if (res.payload.statusCode === 200) {
          message.success(res.payload.message);
          setIsModalVisible(false);
        } else {
          message.error(res.payload.message);
          setIsModalVisible(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setIsModalVisible(false);
        console.log(err);
      });
  };

  const props1 = {
    name: "file",
    accept: ".pdf, .xls, .xlsx",
    maxCount: 1,
    beforeUpload: () => {
      return false;
    },
    onChange(info) {
      //console.log(info);
      form.setFieldsValue("document1", {
        file: info.file,
        fileList: info.fileList,
      });
      let formData = new FormData();
      formData.append("file", info.file);
      formData.append("moduleName", "timesheet");

      setIsLoading(true);
      axios
        .post(`${API_BASE}local-common/uploadFile`, formData)
        .then((res) => {
          setIsLoading(false);
          setTimeSheet(res.data.data.name);
          setUpdated1(true);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    },
  };

  const columns2 = [
    {
      title: "Change Request Notes",
      dataIndex: "notes",
      key: "Notes",
    },
  ];

  return (
    <>
      <Row justify="end">
        {/* <Col Span={4}>
          <Select
            placeholder="Select Status"
            defaultValue={1}
            onChange={(e) => setStatus(e)}
          >
            <Select.Option key={1} value={1}>
              Updated Hour Submitted
            </Select.Option>
            <Select.Option key={2} value={2}>
              Job Confirmed
            </Select.Option>
            <Select.Option key={3} value={3}>
              Job Started
            </Select.Option>
            <Select.Option key={4} value={4}>
              Job Done
            </Select.Option>
          </Select>
        </Col> */}

        {/* {status ? ( */}
        <>
          <Col Span={4}>
            <Button className="btn-primary" type="primary" onClick={note}>
              Add Note
            </Button>
          </Col>
          {/* <Col Span={4}>
            <Button className="btn-primary" type="primary" onClick={showModal}>
              Add TimeSheet
            </Button>
          </Col> */}
        </>
        {/* ) : null} */}
      </Row>

      <Form
        ref={prop.refs}
        form={form}
        name="basic12"
        onFinish={onFinish}
        autoComplete="off"
      >
        {dataSource.length ? (
          <div className="rows">
            <div className="col-lg-12">
              <div className="common-table">
                <Table
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  loading={isLoading}
                  pagination={false}
                  scroll={{ x: 900 }}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <span style={{ color: "red" }}>{error}</span>

        {status ? (
          <Row>
            <div className="col-lg-12">
              <div className="common-table">
                <Table
                  bordered
                  dataSource={notes}
                  columns={columns1}
                  scroll={{ x: 800 }}
                  //loading={isLoading}
                  pagination={{
                    current: page,
                    defaultPageSize: limit,
                    total: totalNotes,
                    showTotal: (total) => `Total ${total} records`,
                    onChange: (page, pagesize) => {
                      setPage(page);
                      setSkip((page - 1) * pagesize);
                    },
                  }}
                />
              </div>
            </div>
          </Row>
        ) : null}

        <Modal
          title="Timesheet Upload"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Upload
            {...props1}
            fileList={
              timeSheet
                ? [
                    {
                      uid: -1,
                      name: updated1 ? "New Document" : "Old Document",
                      status: "done",
                      url: baseURL + timeSheet,
                    },
                  ]
                : []
            }
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
            {!timeSheet ? (
              <span style={{ color: "red" }}>Please select File</span>
            ) : (
              <></>
            )}
          </Upload>
        </Modal>

        <Row>
          <div className="col-lg-12">
            <div className="common-table">
              <Table
                bordered
                dataSource={notes}
                columns={columns2}
                scroll={{ x: 900 }}
                //loading={isLoading}
                pagination={{
                  current: page,
                  defaultPageSize: limit,
                  total: totalNotes,
                  showTotal: (total) => `Total ${total} records`,
                  onChange: (page, pagesize) => {
                    setPage(page);
                    setSkip((page - 1) * pagesize);
                  },
                }}
              />
            </div>
          </div>
        </Row>

        <AddNote
          notesType={5}
          setexcelInfoVisible={setexcelInfoVisible}
          excelInfoVisible={excelInfoVisible}
        />
      </Form>
    </>
  );
}

export default Trial;
