import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE } from "../constants/api";

const initialState = {
  users: {},
  user: [],
  chekedServices: [],
  edited: false,
  form: {},
};

export const addUser = createAsyncThunk("user/addUser", async (data) => {
  try {
    const response = await axios.post(`${API_BASE}v1/user/addUser`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
});

export const changeUserStatus = createAsyncThunk(
  "user/changeUserStatus",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/user/activeInactiveUser`,
        data
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (userId) => {
    try {
      const response = await axios.delete(
        `${API_BASE}v1/user/deleteUser/${userId}`
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getUsers = createAsyncThunk("user/getUsers", async (data) => {
  try {
    const response = await axios.post(`${API_BASE}v1/user/getUserList`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
});

export const getUser = createAsyncThunk("user/getUser", async (id) => {
  try {
    const response = await axios.get(`${API_BASE}v1/user/getUserDetails/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
});

export const editUser = createAsyncThunk("user/editUser", async (data) => {
  try {
    //console.log(data);
    const response = await axios.post(`${API_BASE}v1/user/udateuser`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
});
  
export const changepassword = createAsyncThunk(
  "user/changePassword",
  async (data) => {
    try { 
      const response = await axios.post(
        `${API_BASE}v1/user/changePassword`,
        data
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);
    
export const uploadBulkUser = createAsyncThunk(
  "user/uploadBulkUser",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/user/uploadBulkUserData`,
        data
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);
  
export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/user/resetPassword`,
        data
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }  
  }
);

export const setFirstPassword = createAsyncThunk(
  "user/setFirstPassword",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/user/setNewPassword`,
        data
      );
      return response.data;
    } catch (err) {
      return err.response.data.message;
    }
  }
);

export const getListUsers = createAsyncThunk(
  "user/getListUsers",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/user/getListOfUserName`,
        data
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

// export const getformInstance = createAsyncThunk(
//   "user/getForm",
//   async (data) => {
//     try {
//       const response = await axios.post(
//         `${API_BASE}v1/user/uploadBulkUserData`,
//         data
//       );
//       return response.data;
//     } catch (err) {
//       return err.response.data;
//     }
//   }
// );

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    storeUserServices: (state, action) => {
      state.chekedServices = action.payload;
    },
    storeFormInstance: (state, action) => {
      //console.log(action.payload);
      state.form = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.edited = !state.edited;
      //return action.payload;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      //return action.payload;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload.data;
      //return action.payload;
    });
    builder.addCase(changeUserStatus.fulfilled, (state, action) => {
      state.edited = !state.edited;
      //return action.payload;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.edited = !state.edited;
      //return action.payload;
    });
    builder.addCase(editUser.fulfilled, (state, action) => {
      state.edited = !state.edited;
      //return action.payload;
    });
    builder.addCase(setFirstPassword.fulfilled, (state, action) => {
      state.edited = !state.edited;
    });
    builder.addCase(getListUsers.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const { storeUserServices, storeFormInstance } = userSlice.actions;

export default userSlice.reducer;
