import React, { useEffect, useState } from "react";
import {
  InputNumber,
  Row,
  Col,
  Table,
  Tree,
  Form,
  Input,
  message,
  Select,
  Spin,
} from "antd";
import { useDispatch } from "react-redux/es/exports";
import {
  fillClientForm,
  getClientDetail,
} from "./../../../../Redux/clientFormSlice";
import axios from "axios";
import { API_BASE } from "../../../../constants/api";

let columns2;
const Service = (prop) => {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [defaultCheckedKeys, setDefaultCheckedKeys] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [timeObject, setTimeObject] = useState();

  const [error, setError] = useState();
  const [error1, setError1] = useState();

  const [dataSource1, setDataSource1] = useState([]);
  const [dataSource2, setDataSource2] = useState([]);
  const [subServices, setSubServices] = useState([]);

  const [service, setService] = useState();
  const [allService, setAllService] = useState();
  const [serviceValues, setServiceValues] = useState();

  const [software, setSoftware] = useState();
  const [industry, setIndustry] = useState();
  const [expertise, setExpertise] = useState();

  const dispatch = useDispatch();

  const tabData = JSON.parse(localStorage.getItem("tabData"));
  //console.log("treeData", treeData);

  //getService
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${API_BASE}v1/service/getServiceById/${prop.serviceId}`)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          setService(res.data.data);
          let newObject = {};
          if (res.data.data.availableTime.halfTime) {
            newObject = { ...newObject, HT: 1 };
          }
          if (res.data.data.availableTime.fullTime) {
            newObject = { ...newObject, FT: 1 };
          }
          if (res.data.data.availableTime.adHoc) {
            newObject = { ...newObject, Adhoc: 1 };
          }
          setSubServices(res.data.data.serviceType);
          setTimeObject(newObject);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  if (timeObject) {
    columns2 = [
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "HT",
        dataIndex: "HT",
        key: "HT",
        render: (row, index) => (
          <Form.Item
            className="form-items"
            name={"ht" + index.key}
            initialValue={row}
            rules={[
              {
                required: true,
                message: "Please input valid number!",
              },
              () => ({
                validator(_, value) {
                  if (value && value < 0) {
                    return Promise.reject("provide valid number");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <div>
              <InputNumber
                value={row}
                onChange={(e) => handleTableChange(e, "HT", index.key)}
              />
            </div>
          </Form.Item>
        ),
      },
      {
        title: "FT",
        dataIndex: "FT",
        key: "FT",
        render: (row, index) => (
          <Form.Item
            className="form-items"
            name={"ft" + index.key}
            initialValue={row}
            rules={[
              {
                required: true,
                message: "Please input valid number!",
              },
              () => ({
                validator(_, value) {
                  if (value && value < 0) {
                    return Promise.reject("provide valid number");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <div>
              <InputNumber
                value={row}
                onChange={(e) => handleTableChange(e, "FT", index.key)}
              />
            </div>
          </Form.Item>
        ),
      },
      {
        title: "Adhoc",
        dataIndex: "Adhoc",
        key: "Adhoc",
        render: (row, index) => (
          <Form.Item
            className="form-items"
            name={"adhoc" + index.key}
            initialValue={row}
            rules={[
              {
                required: true,
                message: "Please input valid number!",
              },
              () => ({
                validator(_, value) {
                  if (value && value < 0) {
                    return Promise.reject("provide valid number");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <div>
              <InputNumber
                value={row}
                onChange={(e) => handleTableChange(e, "Adhoc", index.key)}
              />
            </div>
          </Form.Item>
        ),
      },
    ];

    columns2 = columns2.filter((data) => {
      let abc = false;
      for (let key in timeObject) {
        if (data.title === "Title") {
          abc = true;
        }
        if (key === data.title) {
          abc = true;
        }
      }
      if (abc) return true;
      else return false;
    });
  }

  const handleTableChange = (e, prop, key) => {
    setDataSource(
      dataSource.map((data) => {
        if (data.key === key) {
          return { ...data, [prop]: e };
        } else {
          return data;
        }
      })
    );
  };

  useEffect(() => {
    if (subServices && subServices?.length) {
      setTreeData(
        subServices &&
          subServices?.map((data) => {
            return {
              title: data.title,
              key: data._id,
              children: data.subService.map((data1) => {
                return {
                  title: data1.title,
                  key: data1._id,
                  parentId: data._id,
                };
              }),
            };
          })
      );
    }
  }, [subServices]);

  //getSoftware
  useEffect(() => {
    const data = { limit: 10000 };
    if (!software) {
      setIsLoading(true);
      axios
        .post(`${API_BASE}v1/software/getSoftwareList`, data)
        .then((res) => {
          setIsLoading(false);
          if (res.data.statusCode === 200) {
            //console.log();
            setSoftware(res.data.data.list.filter((data) => data.isActive));
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, []);

  //getIndustry
  useEffect(() => {
    const data = { limit: 10000 };
    if (!industry) {
      setIsLoading(true);
      axios
        .post(`${API_BASE}v1/industry/getIndustryList`, data)
        .then((res) => {
          setIsLoading(false);
          if (res.data.statusCode === 200) {
            setIndustry(res.data.data.list.filter((data) => data.isActive));
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, []);

  //getExpertise
  useEffect(() => {
    const data = { limit: 10000 };
    //if (!expertise) {
    setIsLoading(true);
    axios
      .post(`${API_BASE}v1/expertise/getExpertiseList`, data)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          let tarray = res.data.data.list.filter((data) => data.isActive);
          setDataSource1(
            tarray.map((data) => {
              return {
                key: data._id,
                DOC: data.title,
                NOR: 1,
              };
            })
          );
          setExpertise(res.data.data.list.filter((data) => data.isActive));
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
    //}
  }, [serviceValues]);

  //updating checked Nodes
  useEffect(() => {
    //adding childIds

    let ids = [];
    if (dataSource.length === 0) {
      setDefaultCheckedKeys(ids);
    }
    if (dataSource.length && treeData.length) {
      dataSource.map((data) => {
        //console.log(data);
        ids = [...ids, ...data?.childIds];
        let abc = false;
        treeData.map((data1) => {
          if (data1?.key === data?.parentId) {
            if (data1?.children?.length === data?.childIds?.length) {
              abc = true;
            }
          }
        });
        if (abc) {
          ids = [...ids, data.parentId];
        }
      });
      //console.log(ids);
      setDefaultCheckedKeys(ids);
    }
  }, [dataSource]);

  //getting filled service data
  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    if (clientid) {
      setIsLoading(true);
      dispatch(getClientDetail(clientid))
        .then((res) => {
          setIsLoading(false);
          if (res.payload.statusCode === 200) {
            setAllService(res.payload.data);
            let currentService = res.payload.data.selectedService.find(
              (data) => data.selectedServiceID === prop.serviceId
            );
            setServiceValues(currentService);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, []);

  //console.log(dataSource1);

  //setting Form Values
  useEffect(() => {
    // console.log(dataSource1);
    //console.log(serviceValues);
    // console.log(expertise);
    if (serviceValues) {
      form.setFieldsValue({
        taxServices: serviceValues?.taxReturn?.count,
        personal: serviceValues?.taxReturn?.personal,
        business: serviceValues?.taxReturn?.business,

        industry: serviceValues?.selectedIndustryIDS?.filter((data) => {
          if (industry?.find((data1) => data1._id === data)) {
            return true;
          } else {
            return false;
          }
        }),
        software: serviceValues?.selectedSoftwareIDS?.filter((data) => {
          if (software?.find((data1) => data1._id === data)) {
            return true;
          } else {
            return false;
          }
        }),
      });

      //Expertise
      //console.log(dataSource1);
      if (dataSource1.length) {
        //Attaching Title to dataSource2
        let tarray = serviceValues?.selectedExpertiseRequired?.map((data) => {
          let title = dataSource1?.find((data1) => data1.key === data.id);
          if (title) {
            return {
              key: data.id,
              DOC: title?.DOC,
              NOR: data.noOfResources,
            };
          } else {
            return {};
          }
        });
        tarray = tarray.filter((data) => Object.keys(data).length !== 0);
        //console.log(tarray);
        setDataSource2(tarray);

        //Updating DataSource1 With Latest NOR Value
        let array2 = dataSource1?.map((data) => {
          let tobject = tarray?.find((data1) => data1.key === data.key);
          if (tobject) return tobject;
          else return data;
        });
        //console.log(array2);
        setDataSource1(array2);
      }

      //setting default keys
      let tdata = [];
      //adding childIds to checkedNodes
      serviceValues?.selectedService?.[0]?.selectedServiceTypeIDs?.map(
        (data) => {
          tdata = [
            ...tdata,
            ...data.selectedSubServiceIDs.map((data1) => data1),
          ];
        }
      );
      //console.log(tdata)

      //adding parentIds to checkedNodes
      serviceValues?.selectedServiceTypeIDs?.map((data) => {
        treeData.map((data1) => {
          if (data1.key === data.id) {
            if (
              data?.selectedSubServiceIDs?.length === data1?.children?.length
            ) {
              tdata = [...tdata, data.id];
            }
          }
        });
      });
      //console.log("checkedNodes", tdata);
      setDefaultCheckedKeys(tdata);

      //Setting checkedService Table Data
      let tarray3 = serviceValues?.selectedServiceTypeIDs?.map((data) => {
        let tobject = treeData.find((data1) => data1.key === data.id);
        if (tobject) {
          return {
            HT: data.halfTime,
            FT: data.fullTime,
            Adhoc: data.adHoc,
            title: tobject?.title,
            parentId: data.id,
            childIds: data.selectedSubServiceIDs,
            key: data.id,
          };
        } else {
          return {};
        }
      });
      tarray3 = tarray3.filter((data) => Object.keys(data).length !== 0);
      //console.log(tarray3);
      setDataSource(tarray3);
    }
  }, [serviceValues, expertise, treeData]);

  const onFinish = (values) => {
    //console.log(values);
    let clientid = localStorage.getItem("userID");
    //console.log(dataSource2);

    //setting Error
    if (!dataSource.length) {
      setError("Please Select Service");
    }
    if (service?.availableOption?.isExpertiseRequired && !dataSource2.length) {
      setError1("Please Select Expertise");
    }
    if (
      (!dataSource.length && treeData?.length) ||
      (service?.availableOption?.isExpertiseRequired && !dataSource2.length)
    ) {
      return;
    }
    setError("");
    setError1("");

    //Preparing Data
    const newData = {
      selectedService: [
        {
          selectedServiceID: prop.serviceId,
          serviceTitle: prop.serviceTitle,
          selectedServiceTypeIDs: dataSource.map((data) => {
            return {
              id: data.parentId,
              halfTime: data.HT,
              fullTime: data.FT,
              adHoc: data.Adhoc,
              selectedSubServiceIDs: data.childIds,
            };
          }),
          taxReturn: {
            count: parseInt(values.taxServices),
            personal: parseInt(values.personal),
            business: parseInt(values.business),
          },
          selectedIndustryIDS: values.industry,
          selectedSoftwareIDS: values.software,
          selectedExpertiseRequired: dataSource2.map((data) => {
            return {
              id: data.key,
              noOfResources: data.NOR,
            };
          }),
        },
      ],
    };

    const otherData = {
      mainStepNo: prop.serviceNo === prop.noOfServices ? 2 : 1,
      subStepNo: prop.serviceNo,
      userID: clientid,
      status: prop.serviceNo === prop.noOfServices ? 1 : 0,
    };

    // console.log(allService);
    // console.log(prop);
    // let allService1;
    // if (allService?.selectedService?.length) {
    //   //console.log(allService);
    //   if (
    //     allService.selectedService.find(
    //       (data) =>
    //         data.selectedServiceID ===
    //         newData.selectedService[0].selectedServiceID
    //     )
    //   ) {
    //     let tarray = [];
    //     tarray = allService.selectedService.filter(
    //       (data) =>
    //         data.selectedServiceID !==
    //         newData.selectedService[0].selectedServiceID
    //     );
    //     //console.log(tarray);
    //     tarray.push(newData.selectedService[0]);
    //     //console.log(tarray);

    //     if (allService.selectedService.length) {
    //       allService1 = {
    //         ...allService,
    //         selectedService: tarray,
    //         ...otherData,
    //       };
    //     }
    //   } else {
    //     let tarray2 = allService.selectedService;
    //     //console.log(tarray2);
    //     allService1 = {
    //       ...allService,
    //       selectedService: tarray2.push(newData.selectedService[0]),
    //       ...otherData,
    //     };
    //   }
    // }

    let allService1
    if(allService?.selectedService?.length){
      allService1 = {...allService}
      let barray = allService1.selectedService
      console.log(barray)
      
      if(allService1.selectedService.find(data => data.selectedServiceID === service._id)){
        //barray.filter(data1 => data1.selectedServiceID !== service._id)
        //console.log(barray)

        let index = allService1.selectedService.findIndex(data => data.selectedServiceID === service._id)
        console.log(index)
        barray[index] = newData.selectedService[0]
        console.log(barray)
        //console.log(allService.selectedService.filter(data => data.selectedServiceID !== service._id))
        // let index = allService1.selectedService.findIndex(data => data.selectedServiceID === service._id)
        // console.log(index)
        // if(index === 0){
        //   barray = allService1.selectedService.slice(1)
        //   console.log(1)
        // }

        // barray = allService1.selectedService.filter(data => {
        //   console.log(data.selectedServiceID)
        //   console.log(service._id)
        //   console.log(data.selectedServiceID !== service._id)
        //   return data.selectedServiceID !== service._id
        // })
        //console.log(barray)
      }
      //console.log(barray)
      //console.log(newData.selectedService[0])
      //console.log(barray.push(newData.selectedService[0]))
      else {
        console.log(newData.selectedService[0])
        barray = [...barray, newData.selectedService[0]]
        console.log(barray)
      }
      allService1.selectedService = barray
    }

    //console.log(allService1 ? allService1 : { ...newData, ...otherData });

    setIsLoading(true);
    dispatch(
      fillClientForm(allService1 ? allService1 : { ...newData, ...otherData })
    )
      .then((res) => {
        setIsLoading(false);
        //console.log(res.payload);
        if (res.payload.statusCode === 200) {
          message.success(res.payload.message);
          localStorage.setItem("clientID", res.payload.data._id);
          //console.log(prop.serviceNo);
          //console.log(prop.noOfServices);
          if (prop.serviceNo === prop.noOfServices) {
            prop.setCurrent((prev) => prev + 1);
          } else {
            prop.setKey1((prev) => parseInt(prev) + 1);
          }
        } else {
          message.error(res.payload.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleDataSource = (checkedNodes, checked) => {
    let parentChildIds = [];

    if (dataSource.length < checkedNodes.length && checked) {
      if (dataSource.length === 0) {
        //find parent Ids
        checkedNodes.map((data) => {
          let abc = false;
          if (data.parentId) {
            parentChildIds.map((data1) => {
              if (data1.parentId === data.parentId) {
                abc = true;
              }
            });
          }
          if (!abc && data.parentId) {
            parentChildIds.push({
              parentId: data.parentId,
              childIds: [],
              ...timeObject,
            });
          }
        });
        //console.log("parentChildIds", parentChildIds);

        //finding childIds
        checkedNodes.map((data) => {
          if (data.parentId) {
            parentChildIds.map((data1) => {
              if (data1.parentId === data.parentId) {
                data1.childIds.push(data.key);
              } else {
                return data1;
              }
            });
          }
        });
        //console.log("parentChildIds", parentChildIds);

        //finding parent without children
        checkedNodes.map((data) => {
          if (!data.parentId) {
            if (parentChildIds.length) {
              if (
                !parentChildIds.find((data1) => data1.parentId === data.key)
              ) {
                parentChildIds.push({
                  parentId: data.key,
                  childIds: [],
                  ...timeObject,
                });
              }
            } else {
              parentChildIds.push({
                parentId: data.key,
                childIds: [],
                ...timeObject,
              });
            }
          }
        });
        //console.log("parentChildIds", parentChildIds);

        //finding title
        parentChildIds = parentChildIds.map((data) => {
          let title;
          treeData.map((data1) => {
            if (data1.key === data.parentId) {
              title = data1.title;
            }
          });
          return { ...data, title };
        });

        //console.log(parentChildIds);
        setDataSource(parentChildIds);
      } else {
        let object = [];
        //finding new parentIds
        checkedNodes.map((data) => {
          if (data.parentId) {
            let abc = false;
            dataSource.map((data1) => {
              if (data.parentId === data1.parentId) {
                abc = true;
              }
            });
            if (!abc) {
              object.push(data);
            }
          }
        });

        object.map((data) => {
          let abc = false;
          parentChildIds.map((data1) => {
            if (data1.parentId === data.parentId) {
              abc = true;
            }
          });
          if (!abc) {
            parentChildIds.push({
              parentId: data.parentId,
              childIds: [],
              ...timeObject,
            });
          }
        });
        //console.log(object);

        checkedNodes.map((data) => {
          if (!data.parentId) {
            //console.log(dataSource);
            if (dataSource.length) {
              if (
                !dataSource.find((data1) => data1.parentId === data.key) &&
                !object.find((data1) => data1.parentId === data.key)
              ) {
                parentChildIds.push({
                  parentId: data.key,
                  childIds: [],
                  ...timeObject,
                });
              }
            } else {
              parentChildIds.push({
                parentId: data.key,
                childIds: [],
                ...timeObject,
              });
            }
          }
        });
        //console.log(parentChildIds);

        parentChildIds = [...dataSource, ...parentChildIds];
        //console.log(parentChildIds);

        //finding children
        parentChildIds.map((data) => {
          let array1 = [];
          //finding child of Parent
          checkedNodes.map((data1) => {
            if (data1.parentId) {
              if (data.parentId === data1.parentId) {
                array1.push(data1);
              }
            }
          });
          //console.log(array1)

          array1 = array1.filter((data2) => {
            let abc = false;
            data.childIds.map((data3) => {
              if (data3 === data2.key) {
                abc = true;
              }
            });
            return !abc;
          });
          //console.log(array1)

          let ids = array1.map((data) => data.key);
          data.childIds = [...data.childIds, ...ids];
          //console.log(data.childIds)
        });

        //finding title
        parentChildIds = parentChildIds.map((data) => {
          let title;
          treeData.map((data1) => {
            if (data1.key === data.parentId) {
              title = data1.title;
            }
          });
          return { ...data, title };
        });

        //console.log(parentChildIds);
        setDataSource(
          parentChildIds.map((data, index) => {
            return { ...data, key: index };
          })
        );
      }
    } else {
      //removing parenIds
      //console.log(dataSource);
      parentChildIds = dataSource.filter((data) => {
        let abc = false;
        if (data.childIds.length) {
          checkedNodes.map((data1) => {
            if (data1.parentId) {
              if (data1.parentId === data.parentId) {
                abc = true;
              }
            }
          });
          return abc;
        } else {
          if (!checkedNodes.find((data1) => data1.key === data.parentId)) {
            return false;
          } else {
            return true;
          }
        }
      });
      //console.log(parentChildIds);

      //removing childIds
      parentChildIds.map((data) => {
        data.childIds = data.childIds.filter((data1) => {
          let abc = false;
          checkedNodes.map((data2) => {
            if (data2.key === data1) {
              abc = true;
            }
          });
          return abc;
        });
      });

      //console.log(parentChildIds);
      setDataSource(parentChildIds);
    }
  };

  const onCheck = (checkedKeys, info) => {
    handleDataSource(info.checkedNodes, info.checked);
    //console.log("onCheck", checkedKeys, info);
  };

  //Expertise Table
  const columns1 = [
    {
      title: "Designation of Candidate ",
      dataIndex: "DOC",
      key: "DOC",
    },
    {
      title: "No# of resources",
      dataIndex: "NOR",
      key: "NOR",
      render: (value, record) => (
        <Form.Item
          className="form-items"
          name={"NOR" + record.key}
          initialValue={value}
          rules={[
            {
              required: true,
              message: "Please input valid number!",
            },
            () => ({
              validator(_, value) {
                if (value && value < 0) {
                  return Promise.reject("provide valid number");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <div>
            <InputNumber
              value={value}
              onChange={(e) => handleTableChange1(e, "NOR", record.key)}
            />
          </div>
        </Form.Item>
      ),
    },
  ];

  const handleTableChange1 = (e, prop, key) => {
    setDataSource1(
      dataSource1.map((data) => {
        if (data.key === key) {
          return { ...data, [prop]: e };
        } else {
          return data;
        }
      })
    );
    setDataSource2(
      dataSource2?.map((data) => {
        if (data.key === key) {
          return { ...data, [prop]: e };
        } else {
          return data;
        }
      })
    );
  };

  const rowSelection = {
    selectedRowKeys: dataSource2?.map((data) => data.key),
    onChange: (selectedRowKeys, selectedRows) => {
      setDataSource2(selectedRows);
    },
  };

  //console.log(form.getFieldValue());
  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    //console.log(errorFields);
  };

  return (
    <>
      <Form
        //className="sectionadd-form"
        ref={prop.refs}
        //ref={tabData?.refs}
        form={form}
        name={"serviceNo" + tabData?.serviceNo}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        scrollToFirstError={true}
      >
        {isLoading ? (
          <Spin />
        ) : (
          <div className="service-section">
            <div className="row">
              <div className="col-12 pl-0">
                <h5>Select Service Required</h5>
              </div>

              <div className="inner-box">
                <div className="row">
                  {/* Tax return */}
                  {/* {console.log("service", service)} */}
                  {service?.availableOption?.isTaxReturn ? (
                    <div className="col-10">
                      <div className="inner-from-grp">
                        <div className="form-group">
                          <label className="label-title">
                            Number of Tax Returns
                          </label>
                          <Form.Item
                            name="taxServices"
                            rules={[
                              {
                                required: true,
                                message: "Please input valid number!",
                              },
                              () => ({
                                validator(_, value) {
                                  if (value && value < 0) {
                                    return Promise.reject(
                                      "provide valid number"
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Input type="number" />
                          </Form.Item>
                        </div>

                        {service?.taxReturn?.isPersonal ? (
                          <div className="form-group">
                            <label className="label-title">Personal (%)</label>
                            <Form.Item
                              name="personal"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input valid number!",
                                },
                                () => ({
                                  validator(_, value) {
                                    if (value && value < 0) {
                                      return Promise.reject(
                                        "provide valid number"
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Input type="number" />
                            </Form.Item>
                          </div>
                        ) : null}

                        {service?.taxReturn?.isBusiness ? (
                          <div className="form-group">
                            <label className="label-title">Business (%)</label>
                            <Form.Item
                              name="business"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input valid number!",
                                },
                                () => ({
                                  validator(_, value) {
                                    if (value && value < 0) {
                                      return Promise.reject(
                                        "provide valid number"
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Input type="number" />
                            </Form.Item>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}

                  {/* selectServices */}
                  {treeData?.length ? (
                    <div className="col-12">
                      <div className="service-box">
                        <Row>
                          <Col xl={7} sm={24} xs={24}>
                            <label className="label-title">
                              <span className="required">*</span>
                              Select Services
                            </label>
                            <Tree
                              checkable
                              checkedKeys={defaultCheckedKeys}
                              onCheck={onCheck}
                              treeData={treeData}
                            />
                          </Col>
                          <Col xl={15} sm={24} xs={24}>
                            <Table
                              className="table-col"
                              pagination={false}
                              dataSource={dataSource}
                              columns={columns2}
                              scroll={{ x: 500 }}
                            />
                          </Col>
                        </Row>
                        <p style={{ color: "red" }}>{error}</p>
                      </div>
                    </div>
                  ) : null}

                  {/* IndustrySelected */}
                  <div className="col-12" style={{ marginTop: "20px" }}>
                    <Row>
                      <div className="col-lg-6 col-12 pl-0">
                        {service?.availableOption?.isIndustrySelected ? (
                          <div>
                            <label className="label-title">
                              <span className="required">*</span>
                              Select Industry
                            </label>
                            <Form.Item
                              name="industry"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select!",
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                allowClear
                                placeholder="Please select"
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentNode
                                }
                              >
                                {industry &&
                                  industry?.map((data) => (
                                    <Select.Option
                                      key={data._id}
                                      value={data._id}
                                    >
                                      {data.title}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </div>
                        ) : null}
                      </div>

                      <div className="col-lg-6 col-12 pl-0">
                        {/* SoftwareRequired */}
                        {service?.availableOption?.isSoftwareRequired ? (
                          <div className="table-col tab-col">
                            <label className="label-title">
                              <span className="required">*</span>
                              Software Required    
                            </label>
                            <Form.Item
                              name="software"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please Select at least one!",
                              //   },
                              // ]}
                            >
                              <Select  
                                mode="multiple"
                                allowClear
                                placeholder="Please select"
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentNode
                                }   
                              >
                                {software &&
                                  software?.map((data) => (
                                    <Select.Option
                                      key={data._id}
                                      value={data._id}
                                    >
                                      {data.title}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </div>
                        ) : null}
                      </div>
                    </Row>
                  </div>

                  {/* ExpertiseRequired */}
                  {service?.availableOption?.isExpertiseRequired ? (
                    <>
                      <Row>
                        <label className="label-title">
                          <span className="required">*</span>
                          Expertise Required
                          <span
                            style={{
                              color: "red",
                              marginLeft: "10px",
                              display: "inline-block",
                            }}
                          >
                            {error1}
                          </span>
                        </label>
                        <Table
                          columns={columns1}
                          dataSource={dataSource1}
                          pagination={false}
                          rowSelection={rowSelection}
                        />
                      </Row>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>  
        )}
      </Form>
    </>
  );
};

export default Service;
