import React from "react";
import { Link } from "react-router-dom";
import { Collapse, Layout } from "antd";

import "./Faq.scss";
import Loading from "../../components/common/loader";
import Breadcrumbs from "../../components/common/Breadcrumb";
import { Content } from "antd/lib/layout/layout";
import SimpleBar from "simplebar-react";
const { Panel } = Collapse;

const onChange = (key) => {
  //console.log(key);
};

const FaqHome = () => {
  return (
    <>
      <Loading>
        <Layout className="site-layout-background">
          <Content className="admin-webpanel-content">
            <Breadcrumbs content="Edit profile" />
            <SimpleBar className="without-layout-scroll">
            <div className="without-layout">
              <div className="right-panel">
                <div className="faq-home">
                  <div className="help">
                    Having trouble? <Link to="">Get Help</Link>
                  </div>
                  <h2 className="title">Frequently Asked Questions</h2>
                  <Collapse
                    defaultActiveKey={["1"]}
                    onChange={onChange}
                    accordion
                  >
                    <Panel
                      header="What is a Tax Selection Process Works?"
                      key="1"
                    >
                      <p>
                        No, you do not need to pay Instapay where there is no
                        transaction happening. With one of the lowest
                        transaction charges in the industry, pay only when you
                        get paid!
                      </p>
                      <div className="frame">
                        <iframe
                          height="200"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.0583544494657!2d72.54259311542332!3d23.021629522139307!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e848ac8a7faf3%3A0x891159e5666d4832!2sAgile%20Infoways%2C%20Ahmedabad%20%7C%20Flutter%2C%20AngujarJS%2C%20NodeJS%2C%20ReactJS%2C%20Python%20Development%20Company%20India!5e0!3m2!1sen!2sin!4v1665133213363!5m2!1sen!2sin"
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                    </Panel>
                    <Panel header="How to select for services?" key="2">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.Lorem Ipsum is simply dummy text of
                        the printing and typesetting industry.Lorem Ipsum is
                        simply dummy text of the printing and typesetting
                        industry.
                      </p>
                    </Panel>
                    <Panel
                      header="What is the reason of adding payment details for ACH?"
                      key="3"
                    >
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.Lorem Ipsum is simply dummy text of
                        the printing and typesetting industry.
                      </p>
                    </Panel>
                    <Panel
                      header="Can I update service details after signing NDNC?"
                      key="4"
                    >
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                    </Panel>
                    <Panel
                      header="Is there any setup fee or annual maintainance fee that I need to pay regularly?"
                      key="5"
                    >
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.Lorem Ipsum is simply dummy text of
                        the printing and typesetting industry. Lorem Ipsum is
                        simply dummy text of the printing and typesetting
                        industry.Lorem Ipsum is simply dummy text of the
                        printing and typesetting industry.
                      </p>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </div>
            </SimpleBar>
          </Content>
        </Layout>
      </Loading>
    </>
  );
};

export default FaqHome;
