import React, { useState } from "react";
import { Button, Form, message, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import { API_BASE } from "../../../constants/api";

const AddNote = ({ setexcelInfoVisible, excelInfoVisible, notesType }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState();

  const showexcelInfo = () => {
    form.setFieldsValue({ notes: "" });
    setexcelInfoVisible(false);
  };

  const onFinish = (values) => {
    //console.log(values);

    let clientid = localStorage.getItem("clientID");
    const data = {
      ...values,
      clientID: clientid,
      notesType,
    };

    setIsLoading(true);
    axios
      .post(`${API_BASE}v1/clientForm/addClientNotes`, data)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          //console.log(res.data.data);
          message.success(res.data.message);
          setexcelInfoVisible(false);
          form.setFieldsValue({ notes: "" });
        } else {
          message.success(res.data.message);
          form.setFieldsValue({ notes: "" });
          setexcelInfoVisible(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        form.setFieldsValue({ notes: "" });
        setexcelInfoVisible(false);
      });
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        visible={excelInfoVisible}
        onCancel={showexcelInfo}
        centered={true}
        className="common-modal edit-modal"
        footer={[
          <Button className="btn-secondary" onClick={showexcelInfo}>
            Cancel
          </Button>,
          <Button
            form="myForm"
            className="btn-primary"
            htmlType="submit"
            type="primary"
          >
            Submit
          </Button>,
        ]}
      >
        <div className="modal-body">
          <Form
            id="myForm"
            form={form}
            name="basic12"
            onFinish={onFinish}
            autoComplete="off"
          >
            <h4>Add Note</h4>
            <Form.Item
              name="notes"
              rules={[
                {
                  required: true,
                  message: "Please enter note!",
                },
              ]}
            >
              <TextArea rows={5} />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default AddNote;
