import React, { useEffect, useRef, useState } from "react";
import "./dashborad.scss";
import { Col, Card, Layout, Row, Button, Timeline } from "antd";
import { Content } from "antd/lib/layout/layout";
import Breadcrumbs from "../../components/common/Breadcrumb";
import { Pie } from "@ant-design/plots";
import ReactToPdf from "react-to-pdf";
import { useDispatch } from "react-redux";
import { getDashboardDetails } from "../../Redux/Dashboard";
import { getListOfUpcommingMeeting } from "../../Redux/scheduleMeeting";
import moment from "moment";

const color = [
  {
    label: "1",
    key: "#0F6943",
  },
  {
    label: "2",
    key: "#462D91",
  },
  {
    label: "3",
    key: "#5D5A09",
  },
  {
    label: "4",
    key: "#91512D",
  },
  {
    label: "5",
    key: "#2D7391",
  },
  {
    label: "6",
    key: "#5D093E",
  },
  {
    label: "7",
    key: "rgb(111, 66, 66)",
  },
  {
    label: "8",
    key: "rgb(195, 0, 0)",
  },
  {
    label: "9",
    key: "rgb(220, 111, 111)",
  },
  {
    label: "10",
    key: "rgb(248, 117, 49)",
  },
  {
    label: "11",
    key: "rgb(69, 118, 163)",
  },
  {
    label: "12",
    key: "rgb(31, 189, 102)",
  },
  {
    label: "13",
    key: "rgb(88, 60, 60)",
  },
  {
    label: "14",
    key: "rgb(247, 176, 67)",
  },
];

const Dashboard = () => {
  const dispatch = useDispatch();
  const [upcomingMeeting, setUpcomingMeeting] = useState();
  const [dashboardData, setDashboardData] = useState();
  const ref = useRef();
  const data = [
    {
      type: "New Client Registered",
      value: dashboardData?.totalNewRegisterdClient,
    },
    {
      type: "Client internal team members left to be assigned",
      value: dashboardData?.totalClientInternalTeamMemberLeftToBeAssigned,
    },
    {
      type: "Meetings to be scheduled",
      value: dashboardData?.totalMeetingScheduled,
    },
    {
      type: "Client NDA to be sent",
      value: dashboardData?.totalClientNDAToBeSent,
    },
    {
      type: "Client Trial data Not Added",
      value: dashboardData?.totalClientTrialDataNotAdded,
    },
    {
      type: "Review Package To Confirm",
      value: dashboardData?.totalReviewPackage,
    },
    {
      type: "IT checklist to be completed",
      value: dashboardData?.totalITChecklistToBeCompleted,
    },
    {
      type: "Client SLA to be sent",
      value: dashboardData?.totalClientSLAToBeSent,
    },
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "Client Status",
      },
    },
  };

  useEffect(() => {
    dispatch(
      getDashboardDetails({
        userID: localStorage.getItem("user"),
      })
    )
      .then((res) => {
        if (res.payload.statusCode === 200) {
          setDashboardData(res.payload.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(
      getListOfUpcommingMeeting({
        // "clientID": meetingData?.clientID,
        // startDate: moment().startOf('week').format("YYYY-MM-DD"),
        // endDate: moment().endOf('week').format("YYYY-MM-DD")
      })
    )
      .then((res) => {
        if (res.payload.statusCode == 200) {
          setUpcomingMeeting(res.payload.data);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }, []);
  return (
    <Layout className="site-layout-background">
      <Content className="admin-webpanel-content">
        <Breadcrumbs />

        <div className="filter-wrap event-wrap">
          <h2 className="title">Dashboard</h2>
          <Row justify="end" className="btn-box">
            {/* <ReactToPdf targetRef={ref} filename="dashboard.pdf" x="0" y="0">
              {({ toPdf }) => <Button type="primary" htmlType="button" onClick={toPdf} className="btn-primary" >
                Download PDF
              </Button>}
            </ReactToPdf> */}
          </Row>
        </div>

        <div className="event-wrap dashboard-section w-100">
          <div className="row">
            {/*    <div className="col-lg-8">
              <div className="border-right">
                <div ref={ref}>
                  <div className="site-card-wrapper mb-3">
                    <Row gutter={16}>
                      <Col span={24} lg={8} sm={12} className="mb-3">
                        <Card
                          style={{ backgroundColor: "#E0F3EB" }}
                          bordered={false}
                        >
                          <h2 style={{ color: "#0F6943" }}>
                            {
                              dashboardData?.totalClientInternalTeamMemberLeftToBeAssigned
                            }
                          </h2>
                          <p>
                            Client internal team members left to be assigned
                          </p>
                        </Card>
                      </Col>
                      <Col span={24} lg={8} sm={12} className="mb-3">
                        <Card
                          style={{ backgroundColor: "#E8F6FD" }}
                          bordered={false}
                        >
                          <h2 style={{ color: "#09425D" }}>
                            {dashboardData?.totalClientNDAToBeSent}
                          </h2>
                          <p>Client NDA to be sent</p>
                        </Card>
                      </Col>
                      <Col span={24} lg={8} sm={12} className="mb-3">
                        <Card
                          style={{ backgroundColor: "#EEEAFF" }}
                          bordered={false}
                        >
                          <h2 style={{ color: "#462D91" }}>
                            {dashboardData?.totalClientTrialDataNotAdded}
                          </h2>
                          <p>Client Trial data Not Added</p>
                        </Card>
                      </Col>
                      <Col span={24} lg={8} sm={12} className="mb-3">
                        <Card
                          style={{ backgroundColor: "#FAEAF4" }}
                          title=""
                          bordered={false}
                        >
                          <h2 style={{ color: "#5D093E" }}>
                            {dashboardData?.totalReviewPackage}
                          </h2>
                          <p>Review Package To Confirm</p>
                        </Card>
                      </Col>
                      <Col span={24} lg={8} sm={12} className="mb-3">
                        <Card
                          style={{ backgroundColor: "#FFEFEA" }}
                          bordered={false}
                        >
                          <h2 style={{ color: "#91512D" }}>
                            {dashboardData?.totalITChecklistToBeCompleted}
                          </h2>
                          <p>IT checklist to be completed</p>
                        </Card>
                      </Col>
                      <Col span={24} lg={8} sm={12} className="mb-3">
                        <Card
                          style={{ backgroundColor: "#F7FAEA" }}
                          bordered={false}
                        >
                          <h2 style={{ color: "#5D5A09" }}>
                            {dashboardData?.totalClientSLAToBeSent}
                          </h2>
                          <p>Client SLA to be sent</p>
                        </Card>
                      </Col>
                      <Col span={24} lg={8} sm={12} className="mb-3">
                        <Card
                          style={{ backgroundColor: "#EAFEFF" }}
                          bordered={false}
                        >
                          <h2 style={{ color: "#2D7391" }}>
                            {dashboardData?.totalMeetingScheduled}
                          </h2>
                          <p>Meetings to be scheduled</p>
                        </Card>
                      </Col>
                      <Col span={24} lg={8} sm={12} className="mb-3">
                        <Card
                          style={{ backgroundColor: "#F1F1F5" }}
                          bordered={false}
                        >
                          <h2 style={{ color: "#000000" }}>
                            {dashboardData?.totalNewRegisterdClient}
                          </h2>
                          <p>Total new Registered Client</p>
                        </Card>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    <Pie {...config} />
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col-lg-4">
              <h2 className="mb-3">Meeting Schedule</h2>
              <div className="dash-box-aside">
                <Row>
                  <div className="col-lg-12">
                    {upcomingMeeting?.list?.map((val) => (
                      <Timeline>
                        <h4>{moment(val?.date).format("DD/MM/YYYY")}</h4>
                        {val?.meetingArr.length &&
                          val?.meetingArr.map((val1, i) => (
                            <Timeline.Item
                              color={color[i % color.length].key}
                            >{`Call with ${val1?.clientName} ${moment(
                              val1?.meetingDate
                            ).format("hh:mm a")}`}</Timeline.Item>
                          ))}
                      </Timeline>
                    ))}
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default Dashboard;
