import { Row, Form, Input, List, message, Select, Table, Col, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddNote from "../../../components/common/AddNote";
import { API_BASE } from "../../../constants/api";
import {
  fillClientForm,
  getClientDetail,
} from "../../../Redux/clientFormSlice";
import "./package.scss";

const { Option } = Select;

// const data = [
//   "Bookkeeping",
//   "Entering Transactions",
//   "Accounting Finalization",
//   "Basic Individual Tax Preparation",
//   "Dedicated tax review",
//   "Complex Tax Preparation",
//   "Cash/Bank Reconciliation",
//   "Revenue Cycle Controls",
//   "Revenue Cycle Controls",
// ];

const Package = (prop) => {
  const [approved, setApproved] = useState([]);
  const [packageData, setPackageData] = useState();
  const [packageData1, setPackageData1] = useState();
  const [servicesSelected, setServicesSelected] = useState();

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [excelInfoVisible, setexcelInfoVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState();
  const [totalNotes, setTotalNotes] = useState();
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    const data = { limit: 10000, skip, clientID: clientid, notesType: 4 };

    setIsLoading(true);
    axios
      .post(`${API_BASE}v1/clientForm/getClientNotesList`, data)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          setNotes(
            res.data.data.list.map((data) => {
              return { ...data, key: data._id };
            })
          );
          setTotalNotes(res.data.data.list.length);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [skip, excelInfoVisible]);

  useEffect(() => {
    let data = { userType: [6] };
    //setIsLoading(true);
    axios
      .post(`${API_BASE}v1/user/getListOfUserName`, data)
      .then((res) => {
        //setIsLoading(false);
        if (res.data.statusCode === 200) {
          //console.log(res.data.data.list);
          setApproved(res.data.data.list);
        }
      })
      .catch((err) => {
        //setIsLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    //let data = { userType: [6] };
    //setIsLoading(true);
    let clientid = localStorage.getItem("clientID");
    let data = { clientID: clientid };
    axios
      .post(`${API_BASE}v1/clientForm/totalPackageAmount`, data)
      .then((res) => {
        //setIsLoading(false);
        if (res.data.statusCode === 200) {
          //console.log(res.data.data[0]);
          setPackageData(res.data.data[0]);
          setServicesSelected(res?.data?.data?.[0]?.clientServiceNames);
        }
      })
      .catch((err) => {
        //setIsLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (packageData || packageData1) {
      //console.log(packageData);
      form.setFieldsValue({
        packageAmount: packageData?.totalAmount,
        negotiatedRate: packageData1?.negotiatedRate,
        approvedBy: packageData1?.approvedBy,
        notes: packageData1?.note,
      });
    }
  }, [packageData, packageData1]);

  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    if (clientid) {
      //setIsLoading(true);
      dispatch(getClientDetail(clientid))
        .then((res) => {
          //setIsLoading(false);
          if (res.payload.statusCode === 200) {
            setPackageData1(res?.payload?.data?.selectedPackage);
          }
        })
        .catch((err) => {
          //setIsLoading(false);
          console.log(err);
        });
    }
  }, []);

  const onFinish = (values) => {
    //console.log(values);
    let userID = localStorage.getItem("userID");
    let data = {
      userID,
      selectedPackage: {
        negotiatedRate: parseInt(values.negotiatedRate),
        approvedBy: values.approvedBy,
        note: values.notes,
      },
    };
    //console.log(data);
    dispatch(fillClientForm(data))
      .then((res) => {
        //setIsLoading(false);
        if (res.payload.statusCode === 200) {
          message.success(res.payload.message);
          if (!prop.done) {
            prop.setCurrent((prev) => prev + 1);
          } else {
            navigate("/client");
          }
        } else {
          message.error(res.payload.message);
        }
      })
      .catch((err) => {
        //setIsLoading(false);
        console.log(err);
      });
  };

  const columns1 = [
    {
      title: "Change Request Notes",
      dataIndex: "notes",
      key: "Notes",
    },
  ];

  const note = () => {
    setexcelInfoVisible(true);
  };

  return (
    <Form
      ref={prop.refs}
      form={form}
      name="basic12"
      onFinish={onFinish}
      autoComplete="off"
    >
      
      <Row className="mb-25">
      <Col offset={21} Span={4}>
            <Button className="btn-primary" type="primary" onClick={note}>
              Add Note
            </Button>
          </Col>
      </Row>

      <Row className="mb-25">
        <div className="col-lg-12 listing">
          <List
            size="large"
            header={"Services Selected"}
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 2,
              xl: 2,
              xxl: 3,
            }}
            bordered
            dataSource={servicesSelected}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </div>
      </Row>

      <Row>
        <div className="col-lg-4">
          <div className="form-group">
            <label className="label-title">Package Amount</label>
            <Form.Item name="packageAmount">
              <Input readOnly />
            </Form.Item>
          </div>
        </div>

        {(packageData1?.negotiatedRate && packageData1?.negotiatedRate !== 0) ?     
        <div className="col-lg-4">
          <div className="form-group">
            <label className="label-title">Final Negotiated Rate</label>
            <Form.Item
              name="negotiatedRate"
              
              //rules={[{ required: true, message: "Please Enter Amount!" }]}
            >
              <Input type="number" disabled />
            </Form.Item>
          </div>
        </div>
        : null}
         {/*
        <div className="col-lg-4">
          <div className="form-group">
            <label className="label-title">Approved by</label>
            <Form.Item
              name="approvedBy"
              rules={[{ required: true, message: "Please Select!" }]}
            >
              <Select placeholder="Select Approver">
                {approved?.map((data) => {
                  return (
                    <Option key={data._id} value={data._id}>
                      {data.clientName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </div> */}
      </Row>
     
      <Row>
      <Row>
            <div className="col-lg-12">
              <div className="common-table">
                <Table
                  bordered
                  dataSource={notes}
                  columns={columns1}
                  scroll={{ x: 800 }}
                  loading={isLoading}
                  pagination={{
                    current: page,
                    defaultPageSize: limit,
                    total: totalNotes,
                    showTotal: (total) => `Total ${total} records`,
                    onChange: (page, pagesize) => {
                      setPage(page);
                      setSkip((page - 1) * pagesize);
                    },
                  }}
                />
              </div>
            </div>
          </Row>

      <AddNote
          notesType={4}
          setexcelInfoVisible={setexcelInfoVisible}
          excelInfoVisible={excelInfoVisible}
        />


        {/* <div className="col-lg-12">
          <label className="label-title">Add note</label>
          <Form.Item name="notes">
            <Form.Item name="notes">
              <TextArea rows={4} />
            </Form.Item>
          </Form.Item>
        </div> */}
      </Row>
    </Form>
  );
};

export default Package;
