import React from "react";
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import Breadcrumbs from "../../../components/common/Breadcrumb";
import Loading from "../../../components/common/loader";
import { toAbsoluteUrl } from "../../../utils";
import "../profiledetail.scss";

const Activities = () => {
  return (
    <>
      <Loading>
        <Layout className="site-layout-background">
          <Content className="admin-webpanel-content">
            <Breadcrumbs />
            <div className="without-layout">
              <div className="right-panel acitivity-wrap">
                <div className="acitivity-left">
                  <div className="filter-wrap mb-25">
                    <h2 className="title">Activities</h2>
                  </div>
                  <div className="acitivity-list">
                    <figure>
                      <img
                        src={toAbsoluteUrl("../assets/images/activities.svg")}
                        alt="activities"
                      />
                    </figure>
                    <div className="desc">
                      <h2 className="title">Your Account created</h2>
                      <span className="time">5 min ago</span>
                    </div>
                  </div>
                  <div className="acitivity-list">
                    <figure>
                      <img
                        src={toAbsoluteUrl("../assets/images/activities.svg")}
                        alt="activities"
                      />
                    </figure>
                    <div className="desc">
                      <h2 className="title">View and Sign NDA</h2>
                      <span className="time">1 min ago</span>
                    </div>
                  </div>
                  <div className="acitivity-list">
                    <figure>
                      <img
                        src={toAbsoluteUrl("../assets/images/activities.svg")}
                        alt="activities"
                      />
                    </figure>
                    <div className="desc">
                      <h2 className="title">Add your trial service details</h2>
                      <span className="time">1 min ago</span>
                    </div>
                  </div>
                  <div className="acitivity-list">
                    <figure>
                      <img
                        src={toAbsoluteUrl("../assets/images/activities.svg")}
                        alt="activities"
                      />
                    </figure>
                    <div className="desc">
                      <h2 className="title">Trial job started</h2>
                      <span className="time">1 min ago</span>
                    </div>
                  </div>

                  <div className="acitivity-list">
                    <figure>
                      <img
                        src={toAbsoluteUrl("../assets/images/activities.svg")}
                        alt="activities"
                      />
                    </figure>
                    <div className="desc">
                      <h2 className="title">Trial job completed</h2>
                      <span className="time">7 days ago</span>
                    </div>
                  </div>
                  <div className="acitivity-list">
                    <figure>
                      <img
                        src={toAbsoluteUrl("../assets/images/activities.svg")}
                        alt="activities"
                      />
                    </figure>
                    <div className="desc">
                      <h2 className="title">Select Package </h2>
                      <span className="time">7 days ago</span>
                    </div>
                  </div>
                  <div className="acitivity-list">
                    <figure>
                      <img
                        src={toAbsoluteUrl("../assets/images/activities.svg")}
                        alt="activities"
                      />
                    </figure>
                    <div className="desc">
                      <h2 className="title">View and Sign SLA</h2>
                      <span className="time">7 days ago</span>
                    </div>
                  </div>
                  <div className="acitivity-list">
                    <figure>
                      <img
                        src={toAbsoluteUrl("../assets/images/activities.svg")}
                        alt="activities"
                      />
                    </figure>
                    <div className="desc">
                      <h2 className="title">View Team Member Assigned</h2>
                      <span className="time">7 days ago</span>
                    </div>
                  </div>
                  <div className="acitivity-list">
                    <figure>
                      <img
                        src={toAbsoluteUrl("../assets/images/activities.svg")}
                        alt="activities"
                      />
                    </figure>
                    <div className="desc">
                      <h2 className="title">
                        Meeting is scheduled on monday (10/0/2022 at 04:13 PM)
                      </h2>
                      <span className="time">7 days ago</span>
                    </div>
                  </div>
                  <div className="acitivity-list">
                    <figure>
                      <img
                        src={toAbsoluteUrl("../assets/images/activities.svg")}
                        alt="activities"
                      />
                    </figure>
                    <div className="desc">
                      <h2 className="title">IT Checklist Done</h2>
                      <span className="time">7 days ago</span>
                    </div>
                  </div>
                  <div className="acitivity-list">
                    <figure>
                      <img
                        src={toAbsoluteUrl("../assets/images/activities.svg")}
                        alt="activities"
                      />
                    </figure>
                    <div className="desc">
                      <h2 className="title">Please Complete Payment</h2>
                      <span className="time">Please Complete Payment</span>
                    </div>
                  </div>
                </div>
                <div className="acitivity-right">
                  <div className="filter-wrap mb-25">
                    <h2 className="title">Scheduled Meeting Details</h2>
                  </div>
                  <div className="scheduled-list">
                    <span className="title">Duration</span>
                    <span className="time">5 min ago</span>
                  </div>
                  <div className="scheduled-list">
                    <span className="title">Scheduled</span>
                    <span className="time">20.11.2022 12:30 PM EST</span>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </Loading>
    </>
  );
};

export default Activities;
