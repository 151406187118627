import { Route } from "react-router-dom";

import Dashboard from "./pages/Dashborad";
import AuthGuard from "./components/auth";
import ProfileDetail from "./pages/Profile/ProfileDetail";
import ChangePassword from "./pages/Profile/ChangePassword";
import User from "./pages/Admin/BDUser";
import AddUser from "./pages/Admin/BDUser/add";
import Client from "./pages/BD User/Clients";
import AddClient from "./pages/BD User/Clients/add";
import Meeting from "./pages/BD User/Meeting";
import ViewMeeting from "./pages/BD User/Meeting/View";
import EditMeeting from "./pages/BD User/Meeting/Edit";
import ITClient from "./pages/IT/Clients";
import Checklist from "./pages/IT/Clients/view/Checklist";
import Service from "./pages/Admin/Data/Service";
import AddService from "./pages/Admin/Data/Service/add";
import EditService from "./pages/Admin/Data/Service/edit";
import ServiceType from "./pages/Admin/Data/ServiceType";
import AddServiceType from "./pages/Admin/Data/ServiceType/add";
import SubService from "./pages/Admin/Data/SubService";
import AddSubService from "./pages/Admin/Data/SubService/add";
import EditSubService from "./pages/Admin/Data/SubService/edit";
import Expertise from "./pages/Admin/Data/Expertise";
import AddExpertise from "./pages/Admin/Data/Expertise/add";
import ListFaq from "./pages/Admin/Faq";
import AddFaq from "./pages/Admin/Faq/add";
import EditFaq from "./pages/Admin/Faq/edit";
import Template from "./pages/Admin/EmailTemp";
import AddTemp from "./pages/Admin/EmailTemp/add";
import EditTemp from "./pages/Admin/EmailTemp/edit";
import TeamLog from "./pages/Report/TeamLog";
import ClientStatus from "./pages/Report/ClientStatus";
import ManagePackge from "./pages/Admin/Managepackage";
import AddPakages from "./pages/Admin/Managepackage/add";
import Activities from "./pages/Profile/Activities";
import Faq from "./pages/Faq/index"  

const routes = [
  // dashboard
  {
    path: "/dashboard",
    element: (
      <AuthGuard> 
        <Dashboard />
      </AuthGuard>
    ),
  },
        
  // admin-user
  {
    path: "/bdUser",
    element: (
      <AuthGuard>
        <User pageName="BD User" link="bdUser" userType={3} />
      </AuthGuard>
    ),
  },
  {
    path: "/bdUser/add",
    element: (
      <AuthGuard>
        <AddUser pageName="BD User" link="bdUser" pageType="Add" userType={3} />
      </AuthGuard>
    ),
  },
  {
    path: "/bdUser/edit",
    element: (
      <AuthGuard>
        <AddUser
          pageName="BD User"
          link="bdUser"
          pageType="Edit"
          userType={3}
        />
      </AuthGuard>
    ),
  },                                                                            
  // head approver
  {
    path: "/headApprover",
    element: (
      <AuthGuard>
        {/* <Head /> */}
        <User pageName="Head Approver" link="headApprover" userType={6} />
      </AuthGuard>
    ),
  },
  {
    path: "/headApprover/add",
    element: (
      <AuthGuard>
        <AddUser
          pageName="Head Approver"
          link="headApprover"
          pageType="Add"
          userType={6}
        />
      </AuthGuard>
    ),
  },                
  {
    path: "/headApprover/edit",
    element: (
      <AuthGuard>
        <AddUser
          pageName="Head Approver"
          link="headApprover"
          pageType="Edit"
          userType={6}
        />
      </AuthGuard>
    ),
  },
  //  team member
  {
    path: "/teamMember",
    element: (
      <AuthGuard>
        {/* <TeamMember /> */}
        <User pageName="Team Member" link="teamMember" userType={7} />
      </AuthGuard>
    ),
  },
  {
    path: "/teamMember/add",
    element: (
      <AuthGuard>
        <AddUser
          pageName="Team Member"
          link="teamMember"
          pageType="Add"
          userType={7}
        />
      </AuthGuard>
    ),
  },
  {
    path: "/teamMember/edit",
    element: (
      <AuthGuard>
        <AddUser
          pageName="Team Member"
          link="teamMember"
          pageType="Edit"
          userType={7}
        />
      </AuthGuard>
    ),
  },

  //  it user
  {
    path: "/itUser",
    element: (
      <AuthGuard>
        <User pageName="IT User" link="itUser" userType={4} />
      </AuthGuard>
    ),
  },
  {
    path: "/itUser/add",
    element: (
      <AuthGuard>
        <AddUser pageName="IT User" link="itUser" pageType="Add" userType={4} />
      </AuthGuard>
    ),
  },
  {
    path: "/itUser/edit",
    element: (
      <AuthGuard>
        <AddUser
          pageName="IT User"
          link="itUser"
          pageType="Edit"
          userType={4}
        />
      </AuthGuard>
    ),
  },

  //  manager
  {
    path: "/manager",
    element: (
      <AuthGuard>
        <User pageName="Manager" link="manager" userType={5} />
      </AuthGuard>
    ),
  },
  {
    path: "/manager/add",
    element: (
      <AuthGuard>
        <AddUser
          pageName="Manager"
          link="manager"
          pageType="Add"
          userType={5}
        />
      </AuthGuard>
    ),
  },
  {
    path: "/manager/edit",
    element: (
      <AuthGuard>
        <AddUser
          pageName="Manager"
          link="manager"
          pageType="Edit"
          userType={5}
        />
      </AuthGuard>
    ),
  },

  // FAQ
  {
    path: "/faq",
    element: (
      <AuthGuard>
        <ListFaq pageName="faq" link="faq" />
      </AuthGuard>
    ),
  },

  {
    path: "/faq/add",
    element: (
      <AuthGuard>
        <AddFaq pageName="addfaq" link="addfaq" />
      </AuthGuard>
    ),
  },

  {
    path: "/faq/edit/:id",
    element: (
      <AuthGuard>
        <EditFaq pageName="editfaq" link="editfaq" />
      </AuthGuard>
    ),
  },

  // EMAIL-TEMPLATE
  {
    path: "/email",
    element: (
      <AuthGuard>
        <Template pageName="email" link="email" />
      </AuthGuard>
    ),
  },

  {
    path: "/email/add",
    element: (
      <AuthGuard>
        <AddTemp pageName="addemail" link="addemail" />
      </AuthGuard>
    ),
  },

  {
    path: "/email/edit/:id",
    element: (
      <AuthGuard>
        <EditTemp pageName="addemail" link="addemail" />
      </AuthGuard>
    ),
  },

  {
    path: "/profile",
    element: (
      <AuthGuard>
        <ProfileDetail />
      </AuthGuard>
    ),
  },
  {                                                                                                                    
    path: "/profile/changepassword",
    element: (
      <AuthGuard>
        <ChangePassword />
      </AuthGuard>
    ),
  },
  {                                                                                                                    
    path: "/profile/activities",
    element: (
      <AuthGuard>
        <Activities />
      </AuthGuard>
    ),
  },

  // client
  {
    path: "/client",
    element: (
      <AuthGuard>
        <Client />
      </AuthGuard>
    ),
  },
  {
    path: "/client/add_BdUser",
    element: (
      <AuthGuard>
        <AddClient />
      </AuthGuard>
    ),
  },
                                                                                                                                    
  // schedule meeting
  {
    path: "/schedule_meeting",
    element: (
      <AuthGuard>
        <Meeting />
      </AuthGuard>
    ),
  },
  {
    path: "/schedule_meeting/view_meeting",
    element: (
      <AuthGuard>
        <ViewMeeting />
      </AuthGuard>
    ),
  },                                                
  {
    path: "/schedule_meeting/view_meeting/:id",
    element: (
      <AuthGuard>
        <EditMeeting />
      </AuthGuard>
    ),
  },

  // IT client
  {
    path: "/client_it",
    element: (
      <AuthGuard>
        <ITClient />
      </AuthGuard>
    ),
  },
  {
    path: "/client_it/view_checklist",
    element: (
      <AuthGuard>
        <Checklist />
      </AuthGuard>
    ),
  },

  // data
  {
    path: "/data/service",
    element: (
      <AuthGuard>
        <Service />
      </AuthGuard>
    ),
  },
  {
    path: "/data/service/add",
    element: (
      <AuthGuard>                                                                                
        <AddService />
      </AuthGuard>
    ),
  },
  {
    path: "/data/service/edit",
    element: (
      <AuthGuard>
        <EditService />
      </AuthGuard>
    ),
  },

  {
    path: "/data/service_type",
    element: (
      <AuthGuard>
        <ServiceType />
      </AuthGuard>
    ),
  },
  {
    path: "/data/service_type/add",
    element: (
      <AuthGuard>
        <AddServiceType pageType="add" />
      </AuthGuard>
    ),
  },
  {
    path: "/data/service_type/edit",
    element: (
      <AuthGuard>
        <AddServiceType pageType="edit" />
      </AuthGuard>
    ),
  },

  {
    path: "/data/sub_service",
    element: (                            
      <AuthGuard>
        <SubService />
      </AuthGuard>
    ),
  },
  {
    path: "/data/sub_service/add",
    element: (
      <AuthGuard>
        <AddSubService />
      </AuthGuard>
    ),
  },
  {
    path: "/data/sub_service/edit",
    element: (
      <AuthGuard>
        <EditSubService />
      </AuthGuard>
    ),
  },

  {
    path: "/data/expertise",
    element: (
      <AuthGuard>
        <Expertise page="Expertise" />
      </AuthGuard>
    ),
  },
  {
    path: "/data/software",
    element: (
      <AuthGuard>
        <Expertise page="Software" />
      </AuthGuard>
    ),
  },
  {
    path: "/data/industry",
    element: (
      <AuthGuard>
        <Expertise page="Industry" />
      </AuthGuard>
    ),
  },

  {
    path: "/data/expertise/add",
    element: (
      <AuthGuard>
        <AddExpertise page="Expertise" pageType="Add" />
      </AuthGuard>
    ),
  },
  {
    path: "/data/software/add",
    element: (
      <AuthGuard>
        <AddExpertise page="Software" pageType="Add" />
      </AuthGuard>
    ),
  },
  {
    path: "/data/industry/add",
    element: (
      <AuthGuard>
        <AddExpertise page="Industry" pageType="Add" />
      </AuthGuard>
    ),
  },

  {
    path: "/data/expertise/edit",
    element: (
      <AuthGuard>
        <AddExpertise page="Expertise" pageType="Edit" />
      </AuthGuard>
    ),
  },
  {
    path: "/data/software/edit",
    element: (
      <AuthGuard>
        <AddExpertise page="Software" pageType="Edit" />
      </AuthGuard>
    ),
  },
  {
    path: "/data/industry/edit",
    element: (
      <AuthGuard>
        <AddExpertise page="Industry" pageType="Edit" />
      </AuthGuard>
    ),
  },

  {
    path: "/report/client_status_log",
    element: (
      <AuthGuard>
        <ClientStatus />
      </AuthGuard>
    ),
  },
  {
    path: "/report/team_allocation_log",
    element: (
      <AuthGuard>
        <TeamLog />
      </AuthGuard>
    ),
  },

  {
    path: "/manage_packge",
    element: (
      <AuthGuard>
        <ManagePackge />
      </AuthGuard>
    ),
  },
  {
    path: "/manage_packge/edit",
    element: (
      <AuthGuard>
        <AddPakages pageType="Edit" />
      </AuthGuard>
    ),
  },

  {
    path: "/manage_packge/add",
    element: (
      <AuthGuard>
        <AddPakages pageType="Add" />
      </AuthGuard>
    ),
  },

  {
    path: "/faqs",
    element: (
      <AuthGuard>
        <Faq />
      </AuthGuard>
    ),
  },

];

const routeMap = routes.map((r) => <Route key={r.path} {...r} />);

export default routeMap;
                                            